// source: project_info.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.DashboardProjectInfo', null, global);
goog.exportSymbol('proto.proto.DashboardProjectInfoAccount', null, global);
goog.exportSymbol('proto.proto.DashboardProjectInfoPagination', null, global);
goog.exportSymbol('proto.proto.GetDashboardAllCountRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardAllCountResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectInfoAccountRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectInfoAccountResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectInfoRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectInfoResponse', null, global);
goog.exportSymbol('proto.proto.PersonalListDashboardProjectInfoAccountRequest', null, global);
goog.exportSymbol('proto.proto.PersonalListDashboardProjectInfoAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectInfoPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectInfoPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectInfoPagination.displayName = 'proto.proto.DashboardProjectInfoPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardAllCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardAllCountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardAllCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardAllCountRequest.displayName = 'proto.proto.GetDashboardAllCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardAllCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardAllCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardAllCountResponse.displayName = 'proto.proto.GetDashboardAllCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectInfo.displayName = 'proto.proto.DashboardProjectInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectInfoRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectInfoRequest.displayName = 'proto.proto.ListDashboardProjectInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectInfoResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectInfoResponse.displayName = 'proto.proto.ListDashboardProjectInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectInfoAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardProjectInfoAccount.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardProjectInfoAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectInfoAccount.displayName = 'proto.proto.DashboardProjectInfoAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectInfoAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectInfoAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectInfoAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectInfoAccountRequest.displayName = 'proto.proto.ListDashboardProjectInfoAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectInfoAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectInfoAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectInfoAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectInfoAccountResponse.displayName = 'proto.proto.ListDashboardProjectInfoAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PersonalListDashboardProjectInfoAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.PersonalListDashboardProjectInfoAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListDashboardProjectInfoAccountRequest.displayName = 'proto.proto.PersonalListDashboardProjectInfoAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListDashboardProjectInfoAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListDashboardProjectInfoAccountResponse.displayName = 'proto.proto.PersonalListDashboardProjectInfoAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectInfoPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectInfoPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectInfoPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfoPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectInfoPagination}
 */
proto.proto.DashboardProjectInfoPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectInfoPagination;
  return proto.proto.DashboardProjectInfoPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectInfoPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectInfoPagination}
 */
proto.proto.DashboardProjectInfoPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectInfoPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectInfoPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectInfoPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfoPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardProjectInfoPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfoPagination} returns this
 */
proto.proto.DashboardProjectInfoPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardProjectInfoPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfoPagination} returns this
 */
proto.proto.DashboardProjectInfoPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardProjectInfoPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfoPagination} returns this
 */
proto.proto.DashboardProjectInfoPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardProjectInfoPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfoPagination} returns this
 */
proto.proto.DashboardProjectInfoPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardAllCountRequest.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardAllCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardAllCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardAllCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardAllCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardAllCountRequest}
 */
proto.proto.GetDashboardAllCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardAllCountRequest;
  return proto.proto.GetDashboardAllCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardAllCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardAllCountRequest}
 */
proto.proto.GetDashboardAllCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardAllCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardAllCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardAllCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardAllCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated uint64 activity_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * optional string date_begin = 5;
 * @return {string}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date_end = 6;
 * @return {string}
 */
proto.proto.GetDashboardAllCountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardAllCountRequest} returns this
 */
proto.proto.GetDashboardAllCountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardAllCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardAllCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardAllCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardAllCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    params: (f = msg.getParams()) && proto.proto.GetDashboardAllCountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardAllCountResponse}
 */
proto.proto.GetDashboardAllCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardAllCountResponse;
  return proto.proto.GetDashboardAllCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardAllCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardAllCountResponse}
 */
proto.proto.GetDashboardAllCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountCount(value);
      break;
    case 2:
      var value = new proto.proto.GetDashboardAllCountRequest;
      reader.readMessage(value,proto.proto.GetDashboardAllCountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardAllCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardAllCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardAllCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardAllCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.GetDashboardAllCountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 account_count = 1;
 * @return {number}
 */
proto.proto.GetDashboardAllCountResponse.prototype.getAccountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetDashboardAllCountResponse} returns this
 */
proto.proto.GetDashboardAllCountResponse.prototype.setAccountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional GetDashboardAllCountRequest params = 2;
 * @return {?proto.proto.GetDashboardAllCountRequest}
 */
proto.proto.GetDashboardAllCountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.GetDashboardAllCountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.GetDashboardAllCountRequest, 2));
};


/**
 * @param {?proto.proto.GetDashboardAllCountRequest|undefined} value
 * @return {!proto.proto.GetDashboardAllCountResponse} returns this
*/
proto.proto.GetDashboardAllCountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetDashboardAllCountResponse} returns this
 */
proto.proto.GetDashboardAllCountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetDashboardAllCountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    projectIdx: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectInfo}
 */
proto.proto.DashboardProjectInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectInfo;
  return proto.proto.DashboardProjectInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectInfo}
 */
proto.proto.DashboardProjectInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProjectIdx();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional uint64 account_count = 1;
 * @return {number}
 */
proto.proto.DashboardProjectInfo.prototype.getAccountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfo} returns this
 */
proto.proto.DashboardProjectInfo.prototype.setAccountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 project_id = 2;
 * @return {number}
 */
proto.proto.DashboardProjectInfo.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfo} returns this
 */
proto.proto.DashboardProjectInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string project_name = 3;
 * @return {string}
 */
proto.proto.DashboardProjectInfo.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectInfo} returns this
 */
proto.proto.DashboardProjectInfo.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_short_name = 4;
 * @return {string}
 */
proto.proto.DashboardProjectInfo.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectInfo} returns this
 */
proto.proto.DashboardProjectInfo.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 project_idx = 5;
 * @return {number}
 */
proto.proto.DashboardProjectInfo.prototype.getProjectIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectInfo} returns this
 */
proto.proto.DashboardProjectInfo.prototype.setProjectIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectInfoRequest.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectInfoRequest}
 */
proto.proto.ListDashboardProjectInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectInfoRequest;
  return proto.proto.ListDashboardProjectInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectInfoRequest}
 */
proto.proto.ListDashboardProjectInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * optional string date_begin = 7;
 * @return {string}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string date_end = 8;
 * @return {string}
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectInfoRequest} returns this
 */
proto.proto.ListDashboardProjectInfoRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectInfoResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectInfo.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectInfoRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectInfoResponse}
 */
proto.proto.ListDashboardProjectInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectInfoResponse;
  return proto.proto.ListDashboardProjectInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectInfoResponse}
 */
proto.proto.ListDashboardProjectInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectInfo;
      reader.readMessage(value,proto.proto.DashboardProjectInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectInfoRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectInfoRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectInfoRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectInfo items = 1;
 * @return {!Array<!proto.proto.DashboardProjectInfo>}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectInfo, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectInfo>} value
 * @return {!proto.proto.ListDashboardProjectInfoResponse} returns this
*/
proto.proto.ListDashboardProjectInfoResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectInfo}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoResponse} returns this
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectInfoRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectInfoRequest}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectInfoRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectInfoRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectInfoRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectInfoResponse} returns this
*/
proto.proto.ListDashboardProjectInfoResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectInfoResponse} returns this
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectInfoResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardProjectInfoAccount.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectInfoAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectInfoAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectInfoAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfoAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectYesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectInfoAccount}
 */
proto.proto.DashboardProjectInfoAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectInfoAccount;
  return proto.proto.DashboardProjectInfoAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectInfoAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectInfoAccount}
 */
proto.proto.DashboardProjectInfoAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectYes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectInfoAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectInfoAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectInfoAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectInfoAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectYesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.DashboardProjectInfoAccount.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectInfoAccount} returns this
 */
proto.proto.DashboardProjectInfoAccount.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated uint64 project_yes = 2;
 * @return {!Array<number>}
 */
proto.proto.DashboardProjectInfoAccount.prototype.getProjectYesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.DashboardProjectInfoAccount} returns this
 */
proto.proto.DashboardProjectInfoAccount.prototype.setProjectYesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectInfoAccount} returns this
 */
proto.proto.DashboardProjectInfoAccount.prototype.addProjectYes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardProjectInfoAccount} returns this
 */
proto.proto.DashboardProjectInfoAccount.prototype.clearProjectYesList = function() {
  return this.setProjectYesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectInfoAccountRequest.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectInfoAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectInfoAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && proto.proto.DashboardProjectInfoPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    allProject: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectInfoAccountRequest;
  return proto.proto.ListDashboardProjectInfoAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectInfoAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectInfoPagination;
      reader.readMessage(value,proto.proto.DashboardProjectInfoPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllProject(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectInfoAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectInfoAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectInfoPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getAllProject();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardProjectInfoPagination pagination = 1;
 * @return {?proto.proto.DashboardProjectInfoPagination}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardProjectInfoPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectInfoPagination, 1));
};


/**
 * @param {?proto.proto.DashboardProjectInfoPagination|undefined} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
*/
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 activity_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional bool all_project = 7;
 * @return {boolean}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getAllProject = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setAllProject = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.ListDashboardProjectInfoAccountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectInfoAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectInfoAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectInfoAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectInfoAccount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectInfoAccountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectInfoAccountResponse;
  return proto.proto.ListDashboardProjectInfoAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectInfoAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectInfoAccount;
      reader.readMessage(value,proto.proto.DashboardProjectInfoAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectInfoAccountRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectInfoAccountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectInfoAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectInfoAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectInfoAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectInfoAccount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectInfoAccountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectInfoAccount items = 1;
 * @return {!Array<!proto.proto.DashboardProjectInfoAccount>}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectInfoAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectInfoAccount, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectInfoAccount>} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse} returns this
*/
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectInfoAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectInfoAccount}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectInfoAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse} returns this
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectInfoAccountRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectInfoAccountRequest}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectInfoAccountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectInfoAccountRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectInfoAccountRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse} returns this
*/
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectInfoAccountResponse} returns this
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectInfoAccountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListDashboardProjectInfoAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    allProject: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListDashboardProjectInfoAccountRequest;
  return proto.proto.PersonalListDashboardProjectInfoAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllProject(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListDashboardProjectInfoAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      1,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getAllProject();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated uint64 activity_ids = 1;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional bool all_project = 6;
 * @return {boolean}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getAllProject = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setAllProject = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string date_begin = 7;
 * @return {string}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string date_end = 8;
 * @return {string}
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountRequest} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListDashboardProjectInfoAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountResponse}
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListDashboardProjectInfoAccountResponse;
  return proto.proto.PersonalListDashboardProjectInfoAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountResponse}
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListDashboardProjectInfoAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListDashboardProjectInfoAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListDashboardProjectInfoAccountResponse} returns this
 */
proto.proto.PersonalListDashboardProjectInfoAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
