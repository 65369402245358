// source: activity_count.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.DashboardActivityCount', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountAll', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountDetailsInterval', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountFilter', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountFilterItem', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountInterval', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountPagination', null, global);
goog.exportSymbol('proto.proto.DashboardActivityCountSort', null, global);
goog.exportSymbol('proto.proto.GetDashboardActivityCountRequest', null, global);
goog.exportSymbol('proto.proto.GetDashboardActivityCountResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityCountDetailsIntervalRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityCountDetailsIntervalResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityCountDetailsRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityCountDetailsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountDetailsInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCountDetailsInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountDetailsInterval.displayName = 'proto.proto.DashboardActivityCountDetailsInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardActivityCountInterval.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardActivityCountInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountInterval.displayName = 'proto.proto.DashboardActivityCountInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCount.displayName = 'proto.proto.DashboardActivityCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountAll = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCountAll, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountAll.displayName = 'proto.proto.DashboardActivityCountAll';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCountFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountFilterItem.displayName = 'proto.proto.DashboardActivityCountFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardActivityCountFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardActivityCountFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountFilter.displayName = 'proto.proto.DashboardActivityCountFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCountSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountSort.displayName = 'proto.proto.DashboardActivityCountSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityCountPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityCountPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityCountPagination.displayName = 'proto.proto.DashboardActivityCountPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardActivityCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetDashboardActivityCountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.GetDashboardActivityCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardActivityCountRequest.displayName = 'proto.proto.GetDashboardActivityCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetDashboardActivityCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetDashboardActivityCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetDashboardActivityCountResponse.displayName = 'proto.proto.GetDashboardActivityCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityCountDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityCountDetailsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityCountDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityCountDetailsRequest.displayName = 'proto.proto.ListDashboardActivityCountDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityCountDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityCountDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityCountDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityCountDetailsResponse.displayName = 'proto.proto.ListDashboardActivityCountDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityCountDetailsIntervalRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityCountDetailsIntervalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityCountDetailsIntervalRequest.displayName = 'proto.proto.ListDashboardActivityCountDetailsIntervalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityCountDetailsIntervalResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityCountDetailsIntervalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityCountDetailsIntervalResponse.displayName = 'proto.proto.ListDashboardActivityCountDetailsIntervalResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountDetailsInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountDetailsInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountDetailsInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityInterval: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountDetailsInterval}
 */
proto.proto.DashboardActivityCountDetailsInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountDetailsInterval;
  return proto.proto.DashboardActivityCountDetailsInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountDetailsInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountDetailsInterval}
 */
proto.proto.DashboardActivityCountDetailsInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountDetailsInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountDetailsInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountDetailsInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivityInterval();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 activity_count = 1;
 * @return {number}
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.getActivityCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountDetailsInterval} returns this
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.setActivityCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_interval = 2;
 * @return {string}
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.getActivityInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountDetailsInterval} returns this
 */
proto.proto.DashboardActivityCountDetailsInterval.prototype.setActivityInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardActivityCountInterval.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    activId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activTextTape: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityCountDetailsInterval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountInterval}
 */
proto.proto.DashboardActivityCountInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountInterval;
  return proto.proto.DashboardActivityCountInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountInterval}
 */
proto.proto.DashboardActivityCountInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivTextTape(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 5:
      var value = new proto.proto.DashboardActivityCountDetailsInterval;
      reader.readMessage(value,proto.proto.DashboardActivityCountDetailsInterval.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivTextTape();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.DashboardActivityCountDetailsInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 activ_id = 1;
 * @return {number}
 */
proto.proto.DashboardActivityCountInterval.prototype.getActivId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
 */
proto.proto.DashboardActivityCountInterval.prototype.setActivId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activ_text = 2;
 * @return {string}
 */
proto.proto.DashboardActivityCountInterval.prototype.getActivText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
 */
proto.proto.DashboardActivityCountInterval.prototype.setActivText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activ_text_tape = 3;
 * @return {string}
 */
proto.proto.DashboardActivityCountInterval.prototype.getActivTextTape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
 */
proto.proto.DashboardActivityCountInterval.prototype.setActivTextTape = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_short_name = 4;
 * @return {string}
 */
proto.proto.DashboardActivityCountInterval.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
 */
proto.proto.DashboardActivityCountInterval.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DashboardActivityCountDetailsInterval items = 5;
 * @return {!Array<!proto.proto.DashboardActivityCountDetailsInterval>}
 */
proto.proto.DashboardActivityCountInterval.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityCountDetailsInterval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityCountDetailsInterval, 5));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityCountDetailsInterval>} value
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
*/
proto.proto.DashboardActivityCountInterval.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.DashboardActivityCountDetailsInterval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityCountDetailsInterval}
 */
proto.proto.DashboardActivityCountInterval.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.DashboardActivityCountDetailsInterval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardActivityCountInterval} returns this
 */
proto.proto.DashboardActivityCountInterval.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityUniq: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activTextTape: jspb.Message.getFieldWithDefault(msg, 5, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCount}
 */
proto.proto.DashboardActivityCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCount;
  return proto.proto.DashboardActivityCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCount}
 */
proto.proto.DashboardActivityCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityUniq(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivTextTape(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivityUniq();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getActivId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getActivText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivTextTape();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint64 activity_count = 1;
 * @return {number}
 */
proto.proto.DashboardActivityCount.prototype.getActivityCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setActivityCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 activity_uniq = 2;
 * @return {number}
 */
proto.proto.DashboardActivityCount.prototype.getActivityUniq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setActivityUniq = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 activ_id = 3;
 * @return {number}
 */
proto.proto.DashboardActivityCount.prototype.getActivId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setActivId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string activ_text = 4;
 * @return {string}
 */
proto.proto.DashboardActivityCount.prototype.getActivText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setActivText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activ_text_tape = 5;
 * @return {string}
 */
proto.proto.DashboardActivityCount.prototype.getActivTextTape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setActivTextTape = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string project_short_name = 6;
 * @return {string}
 */
proto.proto.DashboardActivityCount.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCount} returns this
 */
proto.proto.DashboardActivityCount.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountAll.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountAll.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountAll} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountAll.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityUniq: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activityPoints: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountAll}
 */
proto.proto.DashboardActivityCountAll.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountAll;
  return proto.proto.DashboardActivityCountAll.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountAll} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountAll}
 */
proto.proto.DashboardActivityCountAll.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityUniq(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActivityPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountAll.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountAll.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountAll} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountAll.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getActivityUniq();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getActivityPoints();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 activity_count = 1;
 * @return {number}
 */
proto.proto.DashboardActivityCountAll.prototype.getActivityCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountAll} returns this
 */
proto.proto.DashboardActivityCountAll.prototype.setActivityCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 activity_uniq = 2;
 * @return {number}
 */
proto.proto.DashboardActivityCountAll.prototype.getActivityUniq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountAll} returns this
 */
proto.proto.DashboardActivityCountAll.prototype.setActivityUniq = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 activity_points = 3;
 * @return {number}
 */
proto.proto.DashboardActivityCountAll.prototype.getActivityPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountAll} returns this
 */
proto.proto.DashboardActivityCountAll.prototype.setActivityPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.DashboardActivityCountFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountFilterItem}
 */
proto.proto.DashboardActivityCountFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountFilterItem;
  return proto.proto.DashboardActivityCountFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountFilterItem}
 */
proto.proto.DashboardActivityCountFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.DashboardActivityCountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountFilterItem} returns this
 */
proto.proto.DashboardActivityCountFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountFilterItem} returns this
 */
proto.proto.DashboardActivityCountFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountFilterItem} returns this
 */
proto.proto.DashboardActivityCountFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DashboardActivityCountFilter or = 4;
 * @return {?proto.proto.DashboardActivityCountFilter}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.DashboardActivityCountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountFilter, 4));
};


/**
 * @param {?proto.proto.DashboardActivityCountFilter|undefined} value
 * @return {!proto.proto.DashboardActivityCountFilterItem} returns this
*/
proto.proto.DashboardActivityCountFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DashboardActivityCountFilterItem} returns this
 */
proto.proto.DashboardActivityCountFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DashboardActivityCountFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardActivityCountFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityCountFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountFilter}
 */
proto.proto.DashboardActivityCountFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountFilter;
  return proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountFilter}
 */
proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountFilterItem;
      reader.readMessage(value,proto.proto.DashboardActivityCountFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityCountFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityCountFilterItem items = 1;
 * @return {!Array<!proto.proto.DashboardActivityCountFilterItem>}
 */
proto.proto.DashboardActivityCountFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityCountFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityCountFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityCountFilterItem>} value
 * @return {!proto.proto.DashboardActivityCountFilter} returns this
*/
proto.proto.DashboardActivityCountFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityCountFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityCountFilterItem}
 */
proto.proto.DashboardActivityCountFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityCountFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardActivityCountFilter} returns this
 */
proto.proto.DashboardActivityCountFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountSort}
 */
proto.proto.DashboardActivityCountSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountSort;
  return proto.proto.DashboardActivityCountSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountSort}
 */
proto.proto.DashboardActivityCountSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardActivityCountSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountSort} returns this
 */
proto.proto.DashboardActivityCountSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardActivityCountSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityCountSort} returns this
 */
proto.proto.DashboardActivityCountSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityCountPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityCountPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityCountPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityCountPagination}
 */
proto.proto.DashboardActivityCountPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityCountPagination;
  return proto.proto.DashboardActivityCountPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityCountPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityCountPagination}
 */
proto.proto.DashboardActivityCountPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityCountPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityCountPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityCountPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityCountPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardActivityCountPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountPagination} returns this
 */
proto.proto.DashboardActivityCountPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardActivityCountPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountPagination} returns this
 */
proto.proto.DashboardActivityCountPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardActivityCountPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountPagination} returns this
 */
proto.proto.DashboardActivityCountPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardActivityCountPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityCountPagination} returns this
 */
proto.proto.DashboardActivityCountPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetDashboardActivityCountRequest.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardActivityCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardActivityCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardActivityCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityCountFilter.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardActivityCountRequest}
 */
proto.proto.GetDashboardActivityCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardActivityCountRequest;
  return proto.proto.GetDashboardActivityCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardActivityCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardActivityCountRequest}
 */
proto.proto.GetDashboardActivityCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardActivityCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardActivityCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardActivityCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardActivityCountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityCountFilter}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityCountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityCountFilter|undefined} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
*/
proto.proto.GetDashboardActivityCountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 activity_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 7;
 * @return {boolean}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.GetDashboardActivityCountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.GetDashboardActivityCountRequest} returns this
 */
proto.proto.GetDashboardActivityCountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetDashboardActivityCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetDashboardActivityCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardActivityCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.proto.DashboardActivityCountAll.toObject(includeInstance, f),
    params: (f = msg.getParams()) && proto.proto.GetDashboardActivityCountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetDashboardActivityCountResponse}
 */
proto.proto.GetDashboardActivityCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetDashboardActivityCountResponse;
  return proto.proto.GetDashboardActivityCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetDashboardActivityCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetDashboardActivityCountResponse}
 */
proto.proto.GetDashboardActivityCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountAll;
      reader.readMessage(value,proto.proto.DashboardActivityCountAll.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    case 2:
      var value = new proto.proto.GetDashboardActivityCountRequest;
      reader.readMessage(value,proto.proto.GetDashboardActivityCountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetDashboardActivityCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetDashboardActivityCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetDashboardActivityCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityCountAll.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.GetDashboardActivityCountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DashboardActivityCountAll item = 1;
 * @return {?proto.proto.DashboardActivityCountAll}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.getItem = function() {
  return /** @type{?proto.proto.DashboardActivityCountAll} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountAll, 1));
};


/**
 * @param {?proto.proto.DashboardActivityCountAll|undefined} value
 * @return {!proto.proto.GetDashboardActivityCountResponse} returns this
*/
proto.proto.GetDashboardActivityCountResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetDashboardActivityCountResponse} returns this
 */
proto.proto.GetDashboardActivityCountResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetDashboardActivityCountRequest params = 2;
 * @return {?proto.proto.GetDashboardActivityCountRequest}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.GetDashboardActivityCountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.GetDashboardActivityCountRequest, 2));
};


/**
 * @param {?proto.proto.GetDashboardActivityCountRequest|undefined} value
 * @return {!proto.proto.GetDashboardActivityCountResponse} returns this
*/
proto.proto.GetDashboardActivityCountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.GetDashboardActivityCountResponse} returns this
 */
proto.proto.GetDashboardActivityCountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.GetDashboardActivityCountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityCountDetailsRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityCountDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityCountDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityCountFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardActivityCountSort.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityCountDetailsRequest;
  return proto.proto.ListDashboardActivityCountDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityCountDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardActivityCountSort;
      reader.readMessage(value,proto.proto.DashboardActivityCountSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityCountDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityCountDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardActivityCountSort.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional DashboardActivityCountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityCountFilter}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityCountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityCountFilter|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
*/
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardActivityCountSort sort = 2;
 * @return {?proto.proto.DashboardActivityCountSort}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardActivityCountSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountSort, 2));
};


/**
 * @param {?proto.proto.DashboardActivityCountSort|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
*/
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 8;
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string date_begin = 9;
 * @return {string}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_end = 10;
 * @return {string}
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityCountDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityCountDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityCountDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityCount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardActivityCountDetailsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityCountDetailsResponse;
  return proto.proto.ListDashboardActivityCountDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityCountDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCount;
      reader.readMessage(value,proto.proto.DashboardActivityCount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardActivityCountDetailsRequest;
      reader.readMessage(value,proto.proto.ListDashboardActivityCountDetailsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityCountDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityCountDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityCount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardActivityCountDetailsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityCount items = 1;
 * @return {!Array<!proto.proto.DashboardActivityCount>}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityCount, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityCount>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse} returns this
*/
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityCount}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse} returns this
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardActivityCountDetailsRequest params = 2;
 * @return {?proto.proto.ListDashboardActivityCountDetailsRequest}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardActivityCountDetailsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardActivityCountDetailsRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardActivityCountDetailsRequest|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse} returns this
*/
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsResponse} returns this
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityCountDetailsIntervalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityCountFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardActivityCountSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardActivityCountPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    typeInterval: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityCountDetailsIntervalRequest;
  return proto.proto.ListDashboardActivityCountDetailsIntervalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityCountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardActivityCountSort;
      reader.readMessage(value,proto.proto.DashboardActivityCountSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardActivityCountPagination;
      reader.readMessage(value,proto.proto.DashboardActivityCountPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeInterval(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityCountDetailsIntervalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityCountFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardActivityCountSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardActivityCountPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      8,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTypeInterval();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional DashboardActivityCountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityCountFilter}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityCountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityCountFilter|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
*/
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardActivityCountSort sort = 2;
 * @return {?proto.proto.DashboardActivityCountSort}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardActivityCountSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountSort, 2));
};


/**
 * @param {?proto.proto.DashboardActivityCountSort|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
*/
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardActivityCountPagination pagination = 3;
 * @return {?proto.proto.DashboardActivityCountPagination}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardActivityCountPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityCountPagination, 3));
};


/**
 * @param {?proto.proto.DashboardActivityCountPagination|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
*/
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint64 activity_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 8;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 9;
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string type_interval = 10;
 * @return {string}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getTypeInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setTypeInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_begin = 11;
 * @return {string}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string date_end = 12;
 * @return {string}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalRequest} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityCountDetailsIntervalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityCountInterval.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardActivityCountDetailsIntervalRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityCountDetailsIntervalResponse;
  return proto.proto.ListDashboardActivityCountDetailsIntervalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityCountInterval;
      reader.readMessage(value,proto.proto.DashboardActivityCountInterval.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardActivityCountDetailsIntervalRequest;
      reader.readMessage(value,proto.proto.ListDashboardActivityCountDetailsIntervalRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityCountDetailsIntervalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityCountInterval.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardActivityCountDetailsIntervalRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityCountInterval items = 1;
 * @return {!Array<!proto.proto.DashboardActivityCountInterval>}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityCountInterval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityCountInterval, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityCountInterval>} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} returns this
*/
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityCountInterval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityCountInterval}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityCountInterval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardActivityCountDetailsIntervalRequest params = 2;
 * @return {?proto.proto.ListDashboardActivityCountDetailsIntervalRequest}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardActivityCountDetailsIntervalRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardActivityCountDetailsIntervalRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardActivityCountDetailsIntervalRequest|undefined} value
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} returns this
*/
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityCountDetailsIntervalResponse} returns this
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityCountDetailsIntervalResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
