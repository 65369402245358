import script from "./QuizSsoTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./QuizSsoTable.vue?vue&type=script&setup=true&lang=ts"

import "./QuizSsoTable.vue?vue&type=style&index=0&id=f3c455d4&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip,QTable});
