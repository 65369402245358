// source: activity_account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.DashboardActivityAccount', null, global);
goog.exportSymbol('proto.proto.DashboardActivityAccountFilter', null, global);
goog.exportSymbol('proto.proto.DashboardActivityAccountFilterItem', null, global);
goog.exportSymbol('proto.proto.DashboardActivityAccountPagination', null, global);
goog.exportSymbol('proto.proto.DashboardActivityAccountSort', null, global);
goog.exportSymbol('proto.proto.DashboardActivityUniqAccount', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityAccountRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityAccountResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityUniqAccountRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardActivityUniqAccountResponse', null, global);
goog.exportSymbol('proto.proto.PersonalListDashboardActivityUniqAccountRequest', null, global);
goog.exportSymbol('proto.proto.PersonalListDashboardActivityUniqAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityAccountFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityAccountFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityAccountFilterItem.displayName = 'proto.proto.DashboardActivityAccountFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityAccountFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardActivityAccountFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardActivityAccountFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityAccountFilter.displayName = 'proto.proto.DashboardActivityAccountFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityAccountSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityAccountSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityAccountSort.displayName = 'proto.proto.DashboardActivityAccountSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityAccountPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityAccountPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityAccountPagination.displayName = 'proto.proto.DashboardActivityAccountPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityAccount.displayName = 'proto.proto.DashboardActivityAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityAccountRequest.displayName = 'proto.proto.ListDashboardActivityAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityAccountResponse.displayName = 'proto.proto.ListDashboardActivityAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardActivityUniqAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardActivityUniqAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardActivityUniqAccount.displayName = 'proto.proto.DashboardActivityUniqAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityUniqAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityUniqAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityUniqAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityUniqAccountRequest.displayName = 'proto.proto.ListDashboardActivityUniqAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardActivityUniqAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardActivityUniqAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardActivityUniqAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardActivityUniqAccountResponse.displayName = 'proto.proto.ListDashboardActivityUniqAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PersonalListDashboardActivityUniqAccountRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.PersonalListDashboardActivityUniqAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListDashboardActivityUniqAccountRequest.displayName = 'proto.proto.PersonalListDashboardActivityUniqAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListDashboardActivityUniqAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListDashboardActivityUniqAccountResponse.displayName = 'proto.proto.PersonalListDashboardActivityUniqAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityAccountFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityAccountFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.DashboardActivityAccountFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityAccountFilterItem}
 */
proto.proto.DashboardActivityAccountFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityAccountFilterItem;
  return proto.proto.DashboardActivityAccountFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityAccountFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityAccountFilterItem}
 */
proto.proto.DashboardActivityAccountFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.DashboardActivityAccountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityAccountFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityAccountFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccountFilterItem} returns this
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccountFilterItem} returns this
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccountFilterItem} returns this
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DashboardActivityAccountFilter or = 4;
 * @return {?proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.DashboardActivityAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountFilter, 4));
};


/**
 * @param {?proto.proto.DashboardActivityAccountFilter|undefined} value
 * @return {!proto.proto.DashboardActivityAccountFilterItem} returns this
*/
proto.proto.DashboardActivityAccountFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DashboardActivityAccountFilterItem} returns this
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DashboardActivityAccountFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardActivityAccountFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityAccountFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityAccountFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityAccountFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityAccountFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.DashboardActivityAccountFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityAccountFilter;
  return proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityAccountFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityAccountFilterItem;
      reader.readMessage(value,proto.proto.DashboardActivityAccountFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityAccountFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityAccountFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityAccountFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityAccountFilterItem items = 1;
 * @return {!Array<!proto.proto.DashboardActivityAccountFilterItem>}
 */
proto.proto.DashboardActivityAccountFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityAccountFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityAccountFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityAccountFilterItem>} value
 * @return {!proto.proto.DashboardActivityAccountFilter} returns this
*/
proto.proto.DashboardActivityAccountFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityAccountFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityAccountFilterItem}
 */
proto.proto.DashboardActivityAccountFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityAccountFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardActivityAccountFilter} returns this
 */
proto.proto.DashboardActivityAccountFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityAccountSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityAccountSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityAccountSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityAccountSort}
 */
proto.proto.DashboardActivityAccountSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityAccountSort;
  return proto.proto.DashboardActivityAccountSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityAccountSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityAccountSort}
 */
proto.proto.DashboardActivityAccountSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityAccountSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityAccountSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityAccountSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardActivityAccountSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccountSort} returns this
 */
proto.proto.DashboardActivityAccountSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardActivityAccountSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccountSort} returns this
 */
proto.proto.DashboardActivityAccountSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityAccountPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityAccountPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityAccountPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityAccountPagination}
 */
proto.proto.DashboardActivityAccountPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityAccountPagination;
  return proto.proto.DashboardActivityAccountPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityAccountPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityAccountPagination}
 */
proto.proto.DashboardActivityAccountPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityAccountPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityAccountPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityAccountPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccountPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardActivityAccountPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityAccountPagination} returns this
 */
proto.proto.DashboardActivityAccountPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardActivityAccountPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityAccountPagination} returns this
 */
proto.proto.DashboardActivityAccountPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardActivityAccountPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityAccountPagination} returns this
 */
proto.proto.DashboardActivityAccountPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardActivityAccountPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardActivityAccountPagination} returns this
 */
proto.proto.DashboardActivityAccountPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activTextTape: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activComment: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityAccount}
 */
proto.proto.DashboardActivityAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityAccount;
  return proto.proto.DashboardActivityAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityAccount}
 */
proto.proto.DashboardActivityAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivTextTape(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivTextTape();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActivDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActivComment();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_short_name = 3;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activ_text = 4;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getActivText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setActivText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activ_text_tape = 5;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getActivTextTape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setActivTextTape = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string activ_date = 6;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getActivDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setActivDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string activ_comment = 7;
 * @return {string}
 */
proto.proto.DashboardActivityAccount.prototype.getActivComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityAccount} returns this
 */
proto.proto.DashboardActivityAccount.prototype.setActivComment = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityAccountRequest.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityAccountFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardActivityAccountSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardActivityAccountPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityAccountRequest}
 */
proto.proto.ListDashboardActivityAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityAccountRequest;
  return proto.proto.ListDashboardActivityAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityAccountRequest}
 */
proto.proto.ListDashboardActivityAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityAccountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardActivityAccountSort;
      reader.readMessage(value,proto.proto.DashboardActivityAccountSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardActivityAccountPagination;
      reader.readMessage(value,proto.proto.DashboardActivityAccountPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardActivityAccountSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardActivityAccountPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      8,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional DashboardActivityAccountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityAccountFilter|undefined} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
*/
proto.proto.ListDashboardActivityAccountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardActivityAccountSort sort = 2;
 * @return {?proto.proto.DashboardActivityAccountSort}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardActivityAccountSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountSort, 2));
};


/**
 * @param {?proto.proto.DashboardActivityAccountSort|undefined} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
*/
proto.proto.ListDashboardActivityAccountRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardActivityAccountPagination pagination = 3;
 * @return {?proto.proto.DashboardActivityAccountPagination}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardActivityAccountPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountPagination, 3));
};


/**
 * @param {?proto.proto.DashboardActivityAccountPagination|undefined} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
*/
proto.proto.ListDashboardActivityAccountRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint64 activity_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 8;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 9;
 * @return {boolean}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string date_begin = 10;
 * @return {string}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_end = 11;
 * @return {string}
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityAccountRequest} returns this
 */
proto.proto.ListDashboardActivityAccountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityAccount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardActivityAccountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityAccountResponse}
 */
proto.proto.ListDashboardActivityAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityAccountResponse;
  return proto.proto.ListDashboardActivityAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityAccountResponse}
 */
proto.proto.ListDashboardActivityAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityAccount;
      reader.readMessage(value,proto.proto.DashboardActivityAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardActivityAccountRequest;
      reader.readMessage(value,proto.proto.ListDashboardActivityAccountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityAccount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardActivityAccountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityAccount items = 1;
 * @return {!Array<!proto.proto.DashboardActivityAccount>}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityAccount, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityAccount>} value
 * @return {!proto.proto.ListDashboardActivityAccountResponse} returns this
*/
proto.proto.ListDashboardActivityAccountResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityAccount}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityAccountResponse} returns this
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardActivityAccountRequest params = 2;
 * @return {?proto.proto.ListDashboardActivityAccountRequest}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardActivityAccountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardActivityAccountRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardActivityAccountRequest|undefined} value
 * @return {!proto.proto.ListDashboardActivityAccountResponse} returns this
*/
proto.proto.ListDashboardActivityAccountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityAccountResponse} returns this
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityAccountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardActivityUniqAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardActivityUniqAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardActivityUniqAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityUniqAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activYes: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardActivityUniqAccount}
 */
proto.proto.DashboardActivityUniqAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardActivityUniqAccount;
  return proto.proto.DashboardActivityUniqAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardActivityUniqAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardActivityUniqAccount}
 */
proto.proto.DashboardActivityUniqAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivYes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardActivityUniqAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardActivityUniqAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardActivityUniqAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardActivityUniqAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivYes();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.DashboardActivityUniqAccount.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityUniqAccount} returns this
 */
proto.proto.DashboardActivityUniqAccount.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activ_yes = 2;
 * @return {string}
 */
proto.proto.DashboardActivityUniqAccount.prototype.getActivYes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardActivityUniqAccount} returns this
 */
proto.proto.DashboardActivityUniqAccount.prototype.setActivYes = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityUniqAccountRequest.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityUniqAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityUniqAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityUniqAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityAccountFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardActivityAccountSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardActivityAccountPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityUniqAccountRequest;
  return proto.proto.ListDashboardActivityUniqAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityUniqAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityAccountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardActivityAccountSort;
      reader.readMessage(value,proto.proto.DashboardActivityAccountSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.DashboardActivityAccountPagination;
      reader.readMessage(value,proto.proto.DashboardActivityAccountPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityUniqAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityUniqAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityUniqAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardActivityAccountSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.DashboardActivityAccountPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      8,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional DashboardActivityAccountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityAccountFilter|undefined} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
*/
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardActivityAccountSort sort = 2;
 * @return {?proto.proto.DashboardActivityAccountSort}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardActivityAccountSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountSort, 2));
};


/**
 * @param {?proto.proto.DashboardActivityAccountSort|undefined} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
*/
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DashboardActivityAccountPagination pagination = 3;
 * @return {?proto.proto.DashboardActivityAccountPagination}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardActivityAccountPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountPagination, 3));
};


/**
 * @param {?proto.proto.DashboardActivityAccountPagination|undefined} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
*/
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint64 activity_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 8;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 9;
 * @return {boolean}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string date_begin = 10;
 * @return {string}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_end = 11;
 * @return {string}
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.ListDashboardActivityUniqAccountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardActivityUniqAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardActivityUniqAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardActivityUniqAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityUniqAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardActivityUniqAccount.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardActivityUniqAccountRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardActivityUniqAccountResponse;
  return proto.proto.ListDashboardActivityUniqAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardActivityUniqAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityUniqAccount;
      reader.readMessage(value,proto.proto.DashboardActivityUniqAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardActivityUniqAccountRequest;
      reader.readMessage(value,proto.proto.ListDashboardActivityUniqAccountRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardActivityUniqAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardActivityUniqAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardActivityUniqAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardActivityUniqAccount.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardActivityUniqAccountRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardActivityUniqAccount items = 1;
 * @return {!Array<!proto.proto.DashboardActivityUniqAccount>}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardActivityUniqAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardActivityUniqAccount, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardActivityUniqAccount>} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse} returns this
*/
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardActivityUniqAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardActivityUniqAccount}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardActivityUniqAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse} returns this
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardActivityUniqAccountRequest params = 2;
 * @return {?proto.proto.ListDashboardActivityUniqAccountRequest}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardActivityUniqAccountRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardActivityUniqAccountRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardActivityUniqAccountRequest|undefined} value
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse} returns this
*/
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardActivityUniqAccountResponse} returns this
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardActivityUniqAccountResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListDashboardActivityUniqAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardActivityAccountFilter.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    activIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    allActiv: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListDashboardActivityUniqAccountRequest;
  return proto.proto.PersonalListDashboardActivityUniqAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardActivityAccountFilter;
      reader.readMessage(value,proto.proto.DashboardActivityAccountFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllActiv(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListDashboardActivityUniqAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardActivityAccountFilter.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getActivIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getAllActiv();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardActivityAccountFilter filter = 1;
 * @return {?proto.proto.DashboardActivityAccountFilter}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardActivityAccountFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardActivityAccountFilter, 1));
};


/**
 * @param {?proto.proto.DashboardActivityAccountFilter|undefined} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
*/
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 activity_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 activ_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getActivIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setActivIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.addActivIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.clearActivIdsList = function() {
  return this.setActivIdsList([]);
};


/**
 * optional bool all_activ = 7;
 * @return {boolean}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getAllActiv = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setAllActiv = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountRequest} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListDashboardActivityUniqAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountResponse}
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListDashboardActivityUniqAccountResponse;
  return proto.proto.PersonalListDashboardActivityUniqAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountResponse}
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListDashboardActivityUniqAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListDashboardActivityUniqAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListDashboardActivityUniqAccountResponse} returns this
 */
proto.proto.PersonalListDashboardActivityUniqAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
