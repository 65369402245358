
import Breadcrumbs from "./components/Breadcrumbs/Breadcrumbs.vue";

const DEFAULT_TRANSITION = "fade";
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import SidebarComponent from "./SidebarComponent.vue";

export default defineComponent({
  name: "App",
  components: {
    Breadcrumbs,
    SidebarComponent,
  },
  data() {
    return {
      headerLinkTo: {
        "Монитор пользователей": "/user-profile",
        "Монитор активности и лояльности": "/user-loyalty",
        Нигма: "/",
      } as any,
      showDrawer: false,
      transitionName: DEFAULT_TRANSITION as any,
      isShowAppMenu: false,
      menuAppItems: [
        {
          title: "Главная",
          url: "/",
        },
        {
          title: "Системные пользователи",
          url: "/users/manage",
        },
        {
          title: "НСИ",
          items: [
            {
              title: "Классификаторы",
              url: "/nsi/classifiers",
            },
            {
              title: "Справочник проектов",
              url: "/nsi/project-directory",
            },
            {
              title: "Словарь",
              url: "/nsi/dictionary",
            },
          ],
        },
        {
          title: "Исходные данные",
          items: [
            {
              title: "Анализ",
              url: "/idata/analysis",
            },
            {
              title: "Импорт",
              url: "/idata/import",
            },
            {
              title: "Экспорт",
              url: "/idata/export",
            },
          ],
        },
        {
          title: "Сообщения пользователей",
          items: [
            {
              title: "Обобщение",
              url: "/cls/ag_polls/generalizing",
            },
            {
              title: "Классификация",
              url: "/cls/ag_polls/answers",
            },
            /*
                        {
                            title: 'Пользователей',
                            url: '/cls/users'
                        },
                        */
            {
              title: "Кластеризации",
              url: "/cls/clustering",
            },
          ],
        },
        {
          title: "Оценка пользователей",
          items: [
            {
              title: "Настройка модели",
              url: "/assess/set-model",
            },
            {
              title: "Оценка",
              url: "/assess/analysis",
            },
          ],
        },
      ],
      headerLabel: "Нигма" as any,
      dateUpdate: "",
      updateStatus: true,
    };
  },

  computed: {
    userRole() {
      return store.getters.getUserAccess;
    },

    userData() {
      return store.getters.getUserData;
    },

    productInfoItems() {
      const items = store.getters.getInfoList;
      const filteredList = [];

      if (items) {
        for (const i of items) {
          filteredList.push({
            projectName: i.getProjectName(),
            dateUpdate: i.getDateUpdate().split(" ")[1] + ' ' + i.getDateUpdate().split(" ")[0].split('-').reverse().join("."),
            isSuccess: i.getIsSuccess(),
          });
        }
      }

      return filteredList;
    },
  },

  updated() {
    console.log("UPDATED", this.$router);
  },

  created() {
    this.getUpdateInfoList();
    this.hideAppMenu();
    this.$router.beforeEach((to: any, from: any, next: any) => {
      const toDepth = to.path.split("/");
      const fromDepth = from.path.split("/");
      this.transitionName =
        toDepth.length < fromDepth.length ? "slide-right" : "slide-left";

      next();
    });

    // this.$router.afterEach(() => {
    //   if (this.$route.path == '/') {
    //     this.headerLabel = 'Нигма'
    //   } else if (
    //     this.$route.path.includes('/user-projects') ||
    //     this.$route.path.includes('/user-profile')
    //   ) {
    //     this.headerLabel = 'Монитор пользователей'
    //   } else if (this.$route.path.includes('/user-loyalty')) {
    //     this.headerLabel = 'Монитор активности и лояльности'
    //   }
    // })
  },

  methods: {
    useRoute,
    selectAppMenuItem(url: any) {
      //console.log('selectAppMenuItem url', url)
      if (url) {
        this.hideAppMenu();
        if (this.$route.path !== url) {
          this.$router.push(url);
        }
      }
    },

    toggleAppMenu() {
      this.isShowAppMenu = !this.isShowAppMenu;
    },

    showAppMenu() {
      this.isShowAppMenu = true;
    },

    hideAppMenu() {
      this.isShowAppMenu = false;
    },

    getUpdateInfoList() {
      store.dispatch("getUpdateInfoList").then((res) => {
        this.dateUpdate = res[0].getDateUpdate().split(" ")[1] + ' ' + res[0].getDateUpdate().split(" ")[0].split('-').reverse().join(".");

        for (const i of res) {
          if (!i.getIsSuccess()) {
            this.updateStatus = false;

            break;
          }
        }
      });
    },

    clickLogout() {
      store.dispatch("logout").then(() => {
        location.reload();
      });
    },
  },
});
