// source: poll_load.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var filter_pb = require('./filter_pb.js');
goog.object.extend(proto, filter_pb);
goog.exportSymbol('proto.proto.CheckPollLoadRequest', null, global);
goog.exportSymbol('proto.proto.CheckPollLoadResponse', null, global);
goog.exportSymbol('proto.proto.GetPollLoadAnswersRequest', null, global);
goog.exportSymbol('proto.proto.GetPollLoadAnswersResponse', null, global);
goog.exportSymbol('proto.proto.GetPollLoadVotesByDateRequest', null, global);
goog.exportSymbol('proto.proto.GetPollLoadVotesByDateResponse', null, global);
goog.exportSymbol('proto.proto.ListPollLoadAccountsRequest', null, global);
goog.exportSymbol('proto.proto.ListPollLoadAccountsResponse', null, global);
goog.exportSymbol('proto.proto.ListPollLoadAnswerAccountsRequest', null, global);
goog.exportSymbol('proto.proto.ListPollLoadAnswerAccountsResponse', null, global);
goog.exportSymbol('proto.proto.ListPollLoadRequest', null, global);
goog.exportSymbol('proto.proto.ListPollLoadResponse', null, global);
goog.exportSymbol('proto.proto.LoadPollLoadRequest', null, global);
goog.exportSymbol('proto.proto.LoadPollLoadResponse', null, global);
goog.exportSymbol('proto.proto.PersonalListPollLoadAccountsResponse', null, global);
goog.exportSymbol('proto.proto.PersonalListPollLoadAnswerAccountsResponse', null, global);
goog.exportSymbol('proto.proto.PollAnswer', null, global);
goog.exportSymbol('proto.proto.PollLoad', null, global);
goog.exportSymbol('proto.proto.PollLoadFilter', null, global);
goog.exportSymbol('proto.proto.PollLoadFilterItem', null, global);
goog.exportSymbol('proto.proto.PollLoadPagination', null, global);
goog.exportSymbol('proto.proto.PollLoadSort', null, global);
goog.exportSymbol('proto.proto.PollMemberItem', null, global);
goog.exportSymbol('proto.proto.PollQuestion', null, global);
goog.exportSymbol('proto.proto.PollSourcesDistrChartItem', null, global);
goog.exportSymbol('proto.proto.PollSourcesDistrChartRequest', null, global);
goog.exportSymbol('proto.proto.PollSourcesDistrChartResponse', null, global);
goog.exportSymbol('proto.proto.VotePoint', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollLoad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollLoad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollLoad.displayName = 'proto.proto.PollLoad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollLoadFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollLoadFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollLoadFilterItem.displayName = 'proto.proto.PollLoadFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollMemberItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollMemberItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollMemberItem.displayName = 'proto.proto.PollMemberItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollSourcesDistrChartItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollSourcesDistrChartItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollSourcesDistrChartItem.displayName = 'proto.proto.PollSourcesDistrChartItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollLoadFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollLoadFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.PollLoadFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollLoadFilter.displayName = 'proto.proto.PollLoadFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollLoadSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollLoadSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollLoadSort.displayName = 'proto.proto.PollLoadSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollLoadPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollLoadPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollLoadPagination.displayName = 'proto.proto.PollLoadPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPollLoadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadRequest.displayName = 'proto.proto.ListPollLoadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPollLoadResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPollLoadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadResponse.displayName = 'proto.proto.ListPollLoadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoadPollLoadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoadPollLoadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoadPollLoadRequest.displayName = 'proto.proto.LoadPollLoadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LoadPollLoadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LoadPollLoadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LoadPollLoadResponse.displayName = 'proto.proto.LoadPollLoadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CheckPollLoadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CheckPollLoadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CheckPollLoadRequest.displayName = 'proto.proto.CheckPollLoadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CheckPollLoadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CheckPollLoadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CheckPollLoadResponse.displayName = 'proto.proto.CheckPollLoadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPollLoadAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadAccountsRequest.displayName = 'proto.proto.ListPollLoadAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPollLoadAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPollLoadAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadAccountsResponse.displayName = 'proto.proto.ListPollLoadAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollSourcesDistrChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollSourcesDistrChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollSourcesDistrChartRequest.displayName = 'proto.proto.PollSourcesDistrChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollSourcesDistrChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollSourcesDistrChartResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.PollSourcesDistrChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollSourcesDistrChartResponse.displayName = 'proto.proto.PollSourcesDistrChartResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetPollLoadVotesByDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetPollLoadVotesByDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetPollLoadVotesByDateRequest.displayName = 'proto.proto.GetPollLoadVotesByDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.VotePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.VotePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.VotePoint.displayName = 'proto.proto.VotePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetPollLoadVotesByDateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetPollLoadVotesByDateResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetPollLoadVotesByDateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetPollLoadVotesByDateResponse.displayName = 'proto.proto.GetPollLoadVotesByDateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListPollLoadAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListPollLoadAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListPollLoadAccountsResponse.displayName = 'proto.proto.PersonalListPollLoadAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PollQuestion.repeatedFields_, null);
};
goog.inherits(proto.proto.PollQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollQuestion.displayName = 'proto.proto.PollQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PollAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PollAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PollAnswer.displayName = 'proto.proto.PollAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetPollLoadAnswersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetPollLoadAnswersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetPollLoadAnswersRequest.displayName = 'proto.proto.GetPollLoadAnswersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetPollLoadAnswersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.GetPollLoadAnswersResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.GetPollLoadAnswersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetPollLoadAnswersResponse.displayName = 'proto.proto.GetPollLoadAnswersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadAnswerAccountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListPollLoadAnswerAccountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadAnswerAccountsRequest.displayName = 'proto.proto.ListPollLoadAnswerAccountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListPollLoadAnswerAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListPollLoadAnswerAccountsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListPollLoadAnswerAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListPollLoadAnswerAccountsResponse.displayName = 'proto.proto.ListPollLoadAnswerAccountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListPollLoadAnswerAccountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListPollLoadAnswerAccountsResponse.displayName = 'proto.proto.PersonalListPollLoadAnswerAccountsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollLoad.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollLoad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollLoad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoad.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pollTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pollUserCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pollTotalAnswers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pollOpenAnswers: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pollLoadedAnswers: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pollDateBegin: (f = msg.getPollDateBegin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pollDateEnd: (f = msg.getPollDateEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pollGroup: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pollNote: jspb.Message.getFieldWithDefault(msg, 10, ""),
    pollLoadingFlag: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    pollDateUpdate: (f = msg.getPollDateUpdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pollTarget: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollLoad}
 */
proto.proto.PollLoad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollLoad;
  return proto.proto.PollLoad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollLoad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollLoad}
 */
proto.proto.PollLoad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollUserCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollTotalAnswers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollOpenAnswers(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollLoadedAnswers(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPollDateBegin(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPollDateEnd(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollGroup(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollNote(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPollLoadingFlag(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPollDateUpdate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollLoad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollLoad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollLoad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPollTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPollUserCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPollTotalAnswers();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPollOpenAnswers();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPollLoadedAnswers();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPollDateBegin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPollDateEnd();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPollGroup();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPollNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPollLoadingFlag();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPollDateUpdate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPollTarget();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int64 poll_id = 1;
 * @return {number}
 */
proto.proto.PollLoad.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string poll_title = 2;
 * @return {string}
 */
proto.proto.PollLoad.prototype.getPollTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 poll_user_count = 3;
 * @return {number}
 */
proto.proto.PollLoad.prototype.getPollUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 poll_total_answers = 4;
 * @return {number}
 */
proto.proto.PollLoad.prototype.getPollTotalAnswers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollTotalAnswers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 poll_open_answers = 5;
 * @return {number}
 */
proto.proto.PollLoad.prototype.getPollOpenAnswers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollOpenAnswers = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 poll_loaded_answers = 6;
 * @return {number}
 */
proto.proto.PollLoad.prototype.getPollLoadedAnswers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollLoadedAnswers = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp poll_date_begin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PollLoad.prototype.getPollDateBegin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PollLoad} returns this
*/
proto.proto.PollLoad.prototype.setPollDateBegin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.clearPollDateBegin = function() {
  return this.setPollDateBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollLoad.prototype.hasPollDateBegin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp poll_date_end = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PollLoad.prototype.getPollDateEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PollLoad} returns this
*/
proto.proto.PollLoad.prototype.setPollDateEnd = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.clearPollDateEnd = function() {
  return this.setPollDateEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollLoad.prototype.hasPollDateEnd = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string poll_group = 9;
 * @return {string}
 */
proto.proto.PollLoad.prototype.getPollGroup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollGroup = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string poll_note = 10;
 * @return {string}
 */
proto.proto.PollLoad.prototype.getPollNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool poll_loading_flag = 11;
 * @return {boolean}
 */
proto.proto.PollLoad.prototype.getPollLoadingFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollLoadingFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp poll_date_update = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.PollLoad.prototype.getPollDateUpdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.PollLoad} returns this
*/
proto.proto.PollLoad.prototype.setPollDateUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.clearPollDateUpdate = function() {
  return this.setPollDateUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollLoad.prototype.hasPollDateUpdate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string poll_target = 13;
 * @return {string}
 */
proto.proto.PollLoad.prototype.getPollTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoad} returns this
 */
proto.proto.PollLoad.prototype.setPollTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollLoadFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollLoadFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollLoadFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.PollLoadFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollLoadFilterItem}
 */
proto.proto.PollLoadFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollLoadFilterItem;
  return proto.proto.PollLoadFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollLoadFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollLoadFilterItem}
 */
proto.proto.PollLoadFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.PollLoadFilter;
      reader.readMessage(value,proto.proto.PollLoadFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollLoadFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollLoadFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollLoadFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.PollLoadFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.PollLoadFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoadFilterItem} returns this
 */
proto.proto.PollLoadFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.PollLoadFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoadFilterItem} returns this
 */
proto.proto.PollLoadFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.PollLoadFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoadFilterItem} returns this
 */
proto.proto.PollLoadFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PollLoadFilter or = 4;
 * @return {?proto.proto.PollLoadFilter}
 */
proto.proto.PollLoadFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.PollLoadFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadFilter, 4));
};


/**
 * @param {?proto.proto.PollLoadFilter|undefined} value
 * @return {!proto.proto.PollLoadFilterItem} returns this
*/
proto.proto.PollLoadFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PollLoadFilterItem} returns this
 */
proto.proto.PollLoadFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PollLoadFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollMemberItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollMemberItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollMemberItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollMemberItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    normalName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollMemberItem}
 */
proto.proto.PollMemberItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollMemberItem;
  return proto.proto.PollMemberItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollMemberItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollMemberItem}
 */
proto.proto.PollMemberItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNormalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollMemberItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollMemberItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollMemberItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollMemberItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNormalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sso_id = 1;
 * @return {string}
 */
proto.proto.PollMemberItem.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollMemberItem} returns this
 */
proto.proto.PollMemberItem.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string normal_name = 2;
 * @return {string}
 */
proto.proto.PollMemberItem.prototype.getNormalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollMemberItem} returns this
 */
proto.proto.PollMemberItem.prototype.setNormalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollSourcesDistrChartItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollSourcesDistrChartItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollSourcesDistrChartItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollSourcesDistrChartItem}
 */
proto.proto.PollSourcesDistrChartItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollSourcesDistrChartItem;
  return proto.proto.PollSourcesDistrChartItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollSourcesDistrChartItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollSourcesDistrChartItem}
 */
proto.proto.PollSourcesDistrChartItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollSourcesDistrChartItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollSourcesDistrChartItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollSourcesDistrChartItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string source_name = 1;
 * @return {string}
 */
proto.proto.PollSourcesDistrChartItem.prototype.getSourceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollSourcesDistrChartItem} returns this
 */
proto.proto.PollSourcesDistrChartItem.prototype.setSourceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 value = 2;
 * @return {number}
 */
proto.proto.PollSourcesDistrChartItem.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollSourcesDistrChartItem} returns this
 */
proto.proto.PollSourcesDistrChartItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollLoadFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollLoadFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollLoadFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollLoadFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollLoadFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollLoadFilter}
 */
proto.proto.PollLoadFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollLoadFilter;
  return proto.proto.PollLoadFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollLoadFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollLoadFilter}
 */
proto.proto.PollLoadFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollLoadFilterItem;
      reader.readMessage(value,proto.proto.PollLoadFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollLoadFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollLoadFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollLoadFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PollLoadFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PollLoadFilterItem items = 1;
 * @return {!Array<!proto.proto.PollLoadFilterItem>}
 */
proto.proto.PollLoadFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollLoadFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollLoadFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.PollLoadFilterItem>} value
 * @return {!proto.proto.PollLoadFilter} returns this
*/
proto.proto.PollLoadFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PollLoadFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollLoadFilterItem}
 */
proto.proto.PollLoadFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PollLoadFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollLoadFilter} returns this
 */
proto.proto.PollLoadFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollLoadSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollLoadSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollLoadSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollLoadSort}
 */
proto.proto.PollLoadSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollLoadSort;
  return proto.proto.PollLoadSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollLoadSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollLoadSort}
 */
proto.proto.PollLoadSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollLoadSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollLoadSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollLoadSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.PollLoadSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoadSort} returns this
 */
proto.proto.PollLoadSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.PollLoadSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollLoadSort} returns this
 */
proto.proto.PollLoadSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollLoadPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollLoadPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollLoadPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollLoadPagination}
 */
proto.proto.PollLoadPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollLoadPagination;
  return proto.proto.PollLoadPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollLoadPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollLoadPagination}
 */
proto.proto.PollLoadPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollLoadPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollLoadPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollLoadPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollLoadPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.PollLoadPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoadPagination} returns this
 */
proto.proto.PollLoadPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.PollLoadPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoadPagination} returns this
 */
proto.proto.PollLoadPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.PollLoadPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoadPagination} returns this
 */
proto.proto.PollLoadPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.PollLoadPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollLoadPagination} returns this
 */
proto.proto.PollLoadPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.PollLoadFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.PollLoadSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.PollLoadPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadRequest}
 */
proto.proto.ListPollLoadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadRequest;
  return proto.proto.ListPollLoadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadRequest}
 */
proto.proto.ListPollLoadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollLoadFilter;
      reader.readMessage(value,proto.proto.PollLoadFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.PollLoadSort;
      reader.readMessage(value,proto.proto.PollLoadSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.PollLoadPagination;
      reader.readMessage(value,proto.proto.PollLoadPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.PollLoadFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.PollLoadSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.PollLoadPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional PollLoadFilter filter = 1;
 * @return {?proto.proto.PollLoadFilter}
 */
proto.proto.ListPollLoadRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.PollLoadFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadFilter, 1));
};


/**
 * @param {?proto.proto.PollLoadFilter|undefined} value
 * @return {!proto.proto.ListPollLoadRequest} returns this
*/
proto.proto.ListPollLoadRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadRequest} returns this
 */
proto.proto.ListPollLoadRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PollLoadSort sort = 2;
 * @return {?proto.proto.PollLoadSort}
 */
proto.proto.ListPollLoadRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.PollLoadSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadSort, 2));
};


/**
 * @param {?proto.proto.PollLoadSort|undefined} value
 * @return {!proto.proto.ListPollLoadRequest} returns this
*/
proto.proto.ListPollLoadRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadRequest} returns this
 */
proto.proto.ListPollLoadRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PollLoadPagination pagination = 3;
 * @return {?proto.proto.PollLoadPagination}
 */
proto.proto.ListPollLoadRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.PollLoadPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadPagination, 3));
};


/**
 * @param {?proto.proto.PollLoadPagination|undefined} value
 * @return {!proto.proto.ListPollLoadRequest} returns this
*/
proto.proto.ListPollLoadRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadRequest} returns this
 */
proto.proto.ListPollLoadRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPollLoadResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollLoad.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListPollLoadRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadResponse}
 */
proto.proto.ListPollLoadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadResponse;
  return proto.proto.ListPollLoadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadResponse}
 */
proto.proto.ListPollLoadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollLoad;
      reader.readMessage(value,proto.proto.PollLoad.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListPollLoadRequest;
      reader.readMessage(value,proto.proto.ListPollLoadRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PollLoad.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListPollLoadRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PollLoad items = 1;
 * @return {!Array<!proto.proto.PollLoad>}
 */
proto.proto.ListPollLoadResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollLoad>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollLoad, 1));
};


/**
 * @param {!Array<!proto.proto.PollLoad>} value
 * @return {!proto.proto.ListPollLoadResponse} returns this
*/
proto.proto.ListPollLoadResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PollLoad=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollLoad}
 */
proto.proto.ListPollLoadResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PollLoad, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPollLoadResponse} returns this
 */
proto.proto.ListPollLoadResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListPollLoadRequest params = 2;
 * @return {?proto.proto.ListPollLoadRequest}
 */
proto.proto.ListPollLoadResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListPollLoadRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListPollLoadRequest, 2));
};


/**
 * @param {?proto.proto.ListPollLoadRequest|undefined} value
 * @return {!proto.proto.ListPollLoadResponse} returns this
*/
proto.proto.ListPollLoadResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadResponse} returns this
 */
proto.proto.ListPollLoadResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoadPollLoadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoadPollLoadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoadPollLoadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPollLoadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoadPollLoadRequest}
 */
proto.proto.LoadPollLoadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoadPollLoadRequest;
  return proto.proto.LoadPollLoadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoadPollLoadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoadPollLoadRequest}
 */
proto.proto.LoadPollLoadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoadPollLoadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoadPollLoadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoadPollLoadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPollLoadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 poll_id = 1;
 * @return {number}
 */
proto.proto.LoadPollLoadRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LoadPollLoadRequest} returns this
 */
proto.proto.LoadPollLoadRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LoadPollLoadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LoadPollLoadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LoadPollLoadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPollLoadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LoadPollLoadResponse}
 */
proto.proto.LoadPollLoadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LoadPollLoadResponse;
  return proto.proto.LoadPollLoadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LoadPollLoadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LoadPollLoadResponse}
 */
proto.proto.LoadPollLoadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LoadPollLoadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LoadPollLoadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LoadPollLoadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LoadPollLoadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.LoadPollLoadResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.LoadPollLoadResponse} returns this
 */
proto.proto.LoadPollLoadResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CheckPollLoadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CheckPollLoadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CheckPollLoadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CheckPollLoadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CheckPollLoadRequest}
 */
proto.proto.CheckPollLoadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CheckPollLoadRequest;
  return proto.proto.CheckPollLoadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CheckPollLoadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CheckPollLoadRequest}
 */
proto.proto.CheckPollLoadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CheckPollLoadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CheckPollLoadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CheckPollLoadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CheckPollLoadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CheckPollLoadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CheckPollLoadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CheckPollLoadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CheckPollLoadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isloading: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CheckPollLoadResponse}
 */
proto.proto.CheckPollLoadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CheckPollLoadResponse;
  return proto.proto.CheckPollLoadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CheckPollLoadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CheckPollLoadResponse}
 */
proto.proto.CheckPollLoadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsloading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CheckPollLoadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CheckPollLoadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CheckPollLoadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CheckPollLoadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsloading();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isLoading = 1;
 * @return {boolean}
 */
proto.proto.CheckPollLoadResponse.prototype.getIsloading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CheckPollLoadResponse} returns this
 */
proto.proto.CheckPollLoadResponse.prototype.setIsloading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && filter_pb.Filter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && filter_pb.Sort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && filter_pb.Pagination.toObject(includeInstance, f),
    pollId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadAccountsRequest}
 */
proto.proto.ListPollLoadAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadAccountsRequest;
  return proto.proto.ListPollLoadAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadAccountsRequest}
 */
proto.proto.ListPollLoadAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new filter_pb.Filter;
      reader.readMessage(value,filter_pb.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new filter_pb.Sort;
      reader.readMessage(value,filter_pb.Sort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new filter_pb.Pagination;
      reader.readMessage(value,filter_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      filter_pb.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      filter_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      filter_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.proto.Filter}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.Filter} */ (
    jspb.Message.getWrapperField(this, filter_pb.Filter, 1));
};


/**
 * @param {?proto.proto.Filter|undefined} value
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
*/
proto.proto.ListPollLoadAccountsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
 */
proto.proto.ListPollLoadAccountsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Sort sort = 2;
 * @return {?proto.proto.Sort}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.Sort} */ (
    jspb.Message.getWrapperField(this, filter_pb.Sort, 2));
};


/**
 * @param {?proto.proto.Sort|undefined} value
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
*/
proto.proto.ListPollLoadAccountsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
 */
proto.proto.ListPollLoadAccountsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.proto.Pagination}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, filter_pb.Pagination, 3));
};


/**
 * @param {?proto.proto.Pagination|undefined} value
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
*/
proto.proto.ListPollLoadAccountsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
 */
proto.proto.ListPollLoadAccountsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 poll_id = 4;
 * @return {number}
 */
proto.proto.ListPollLoadAccountsRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListPollLoadAccountsRequest} returns this
 */
proto.proto.ListPollLoadAccountsRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPollLoadAccountsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ssoIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollMemberItem.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && filter_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadAccountsResponse}
 */
proto.proto.ListPollLoadAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadAccountsResponse;
  return proto.proto.ListPollLoadAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadAccountsResponse}
 */
proto.proto.ListPollLoadAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSsoId(value);
      break;
    case 2:
      var value = new proto.proto.PollMemberItem;
      reader.readMessage(value,proto.proto.PollMemberItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new filter_pb.Pagination;
      reader.readMessage(value,filter_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSsoIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.PollMemberItem.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      filter_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string sso_id = 1;
 * @return {!Array<string>}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.getSsoIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
 */
proto.proto.ListPollLoadAccountsResponse.prototype.setSsoIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
 */
proto.proto.ListPollLoadAccountsResponse.prototype.addSsoId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
 */
proto.proto.ListPollLoadAccountsResponse.prototype.clearSsoIdList = function() {
  return this.setSsoIdList([]);
};


/**
 * repeated PollMemberItem items = 2;
 * @return {!Array<!proto.proto.PollMemberItem>}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollMemberItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollMemberItem, 2));
};


/**
 * @param {!Array<!proto.proto.PollMemberItem>} value
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
*/
proto.proto.ListPollLoadAccountsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.PollMemberItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollMemberItem}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.PollMemberItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
 */
proto.proto.ListPollLoadAccountsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional Pagination pagination = 3;
 * @return {?proto.proto.Pagination}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.getPagination = function() {
  return /** @type{?proto.proto.Pagination} */ (
    jspb.Message.getWrapperField(this, filter_pb.Pagination, 3));
};


/**
 * @param {?proto.proto.Pagination|undefined} value
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
*/
proto.proto.ListPollLoadAccountsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAccountsResponse} returns this
 */
proto.proto.ListPollLoadAccountsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAccountsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollSourcesDistrChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollSourcesDistrChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollSourcesDistrChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollSourcesDistrChartRequest}
 */
proto.proto.PollSourcesDistrChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollSourcesDistrChartRequest;
  return proto.proto.PollSourcesDistrChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollSourcesDistrChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollSourcesDistrChartRequest}
 */
proto.proto.PollSourcesDistrChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollSourcesDistrChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollSourcesDistrChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollSourcesDistrChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 poll_id = 2;
 * @return {number}
 */
proto.proto.PollSourcesDistrChartRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollSourcesDistrChartRequest} returns this
 */
proto.proto.PollSourcesDistrChartRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollSourcesDistrChartResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollSourcesDistrChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollSourcesDistrChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollSourcesDistrChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollSourcesDistrChartItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollSourcesDistrChartResponse}
 */
proto.proto.PollSourcesDistrChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollSourcesDistrChartResponse;
  return proto.proto.PollSourcesDistrChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollSourcesDistrChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollSourcesDistrChartResponse}
 */
proto.proto.PollSourcesDistrChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollSourcesDistrChartItem;
      reader.readMessage(value,proto.proto.PollSourcesDistrChartItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollSourcesDistrChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollSourcesDistrChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollSourcesDistrChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollSourcesDistrChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PollSourcesDistrChartItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PollSourcesDistrChartItem items = 1;
 * @return {!Array<!proto.proto.PollSourcesDistrChartItem>}
 */
proto.proto.PollSourcesDistrChartResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollSourcesDistrChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollSourcesDistrChartItem, 1));
};


/**
 * @param {!Array<!proto.proto.PollSourcesDistrChartItem>} value
 * @return {!proto.proto.PollSourcesDistrChartResponse} returns this
*/
proto.proto.PollSourcesDistrChartResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PollSourcesDistrChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollSourcesDistrChartItem}
 */
proto.proto.PollSourcesDistrChartResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PollSourcesDistrChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollSourcesDistrChartResponse} returns this
 */
proto.proto.PollSourcesDistrChartResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetPollLoadVotesByDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetPollLoadVotesByDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetPollLoadVotesByDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadVotesByDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetPollLoadVotesByDateRequest}
 */
proto.proto.GetPollLoadVotesByDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetPollLoadVotesByDateRequest;
  return proto.proto.GetPollLoadVotesByDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetPollLoadVotesByDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetPollLoadVotesByDateRequest}
 */
proto.proto.GetPollLoadVotesByDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetPollLoadVotesByDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetPollLoadVotesByDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetPollLoadVotesByDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadVotesByDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 poll_id = 1;
 * @return {number}
 */
proto.proto.GetPollLoadVotesByDateRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetPollLoadVotesByDateRequest} returns this
 */
proto.proto.GetPollLoadVotesByDateRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.VotePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.VotePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.VotePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.VotePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    votes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.VotePoint}
 */
proto.proto.VotePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.VotePoint;
  return proto.proto.VotePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.VotePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.VotePoint}
 */
proto.proto.VotePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.VotePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.VotePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.VotePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.VotePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVotes();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.proto.VotePoint.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.VotePoint} returns this
 */
proto.proto.VotePoint.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 votes = 2;
 * @return {number}
 */
proto.proto.VotePoint.prototype.getVotes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.VotePoint} returns this
 */
proto.proto.VotePoint.prototype.setVotes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetPollLoadVotesByDateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetPollLoadVotesByDateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetPollLoadVotesByDateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetPollLoadVotesByDateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadVotesByDateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.VotePoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetPollLoadVotesByDateResponse}
 */
proto.proto.GetPollLoadVotesByDateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetPollLoadVotesByDateResponse;
  return proto.proto.GetPollLoadVotesByDateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetPollLoadVotesByDateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetPollLoadVotesByDateResponse}
 */
proto.proto.GetPollLoadVotesByDateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.VotePoint;
      reader.readMessage(value,proto.proto.VotePoint.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetPollLoadVotesByDateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetPollLoadVotesByDateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetPollLoadVotesByDateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadVotesByDateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.VotePoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VotePoint items = 1;
 * @return {!Array<!proto.proto.VotePoint>}
 */
proto.proto.GetPollLoadVotesByDateResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.VotePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.VotePoint, 1));
};


/**
 * @param {!Array<!proto.proto.VotePoint>} value
 * @return {!proto.proto.GetPollLoadVotesByDateResponse} returns this
*/
proto.proto.GetPollLoadVotesByDateResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.VotePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.VotePoint}
 */
proto.proto.GetPollLoadVotesByDateResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.VotePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetPollLoadVotesByDateResponse} returns this
 */
proto.proto.GetPollLoadVotesByDateResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListPollLoadAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListPollLoadAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListPollLoadAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListPollLoadAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListPollLoadAccountsResponse}
 */
proto.proto.PersonalListPollLoadAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListPollLoadAccountsResponse;
  return proto.proto.PersonalListPollLoadAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListPollLoadAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListPollLoadAccountsResponse}
 */
proto.proto.PersonalListPollLoadAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListPollLoadAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListPollLoadAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListPollLoadAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListPollLoadAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListPollLoadAccountsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListPollLoadAccountsResponse} returns this
 */
proto.proto.PersonalListPollLoadAccountsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PollQuestion.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    answersList: jspb.Message.toObjectList(msg.getAnswersList(),
    proto.proto.PollAnswer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollQuestion}
 */
proto.proto.PollQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollQuestion;
  return proto.proto.PollQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollQuestion}
 */
proto.proto.PollQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionTitle(value);
      break;
    case 3:
      var value = new proto.proto.PollAnswer;
      reader.readMessage(value,proto.proto.PollAnswer.deserializeBinaryFromReader);
      msg.addAnswers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuestionTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.PollAnswer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 question_id = 1;
 * @return {number}
 */
proto.proto.PollQuestion.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollQuestion} returns this
 */
proto.proto.PollQuestion.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string question_title = 2;
 * @return {string}
 */
proto.proto.PollQuestion.prototype.getQuestionTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollQuestion} returns this
 */
proto.proto.PollQuestion.prototype.setQuestionTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PollAnswer answers = 3;
 * @return {!Array<!proto.proto.PollAnswer>}
 */
proto.proto.PollQuestion.prototype.getAnswersList = function() {
  return /** @type{!Array<!proto.proto.PollAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollAnswer, 3));
};


/**
 * @param {!Array<!proto.proto.PollAnswer>} value
 * @return {!proto.proto.PollQuestion} returns this
*/
proto.proto.PollQuestion.prototype.setAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.PollAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollAnswer}
 */
proto.proto.PollQuestion.prototype.addAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.PollAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PollQuestion} returns this
 */
proto.proto.PollQuestion.prototype.clearAnswersList = function() {
  return this.setAnswersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PollAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PollAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PollAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    votesCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PollAnswer}
 */
proto.proto.PollAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PollAnswer;
  return proto.proto.PollAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PollAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PollAnswer}
 */
proto.proto.PollAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVotesCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PollAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PollAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PollAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PollAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVotesCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 answer_id = 1;
 * @return {number}
 */
proto.proto.PollAnswer.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollAnswer} returns this
 */
proto.proto.PollAnswer.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string answer_title = 2;
 * @return {string}
 */
proto.proto.PollAnswer.prototype.getAnswerTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PollAnswer} returns this
 */
proto.proto.PollAnswer.prototype.setAnswerTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 votes_count = 3;
 * @return {number}
 */
proto.proto.PollAnswer.prototype.getVotesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PollAnswer} returns this
 */
proto.proto.PollAnswer.prototype.setVotesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetPollLoadAnswersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetPollLoadAnswersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetPollLoadAnswersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadAnswersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pollId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetPollLoadAnswersRequest}
 */
proto.proto.GetPollLoadAnswersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetPollLoadAnswersRequest;
  return proto.proto.GetPollLoadAnswersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetPollLoadAnswersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetPollLoadAnswersRequest}
 */
proto.proto.GetPollLoadAnswersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPollId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetPollLoadAnswersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetPollLoadAnswersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetPollLoadAnswersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadAnswersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPollId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 poll_id = 1;
 * @return {number}
 */
proto.proto.GetPollLoadAnswersRequest.prototype.getPollId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetPollLoadAnswersRequest} returns this
 */
proto.proto.GetPollLoadAnswersRequest.prototype.setPollId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.GetPollLoadAnswersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetPollLoadAnswersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetPollLoadAnswersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetPollLoadAnswersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadAnswersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.PollQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetPollLoadAnswersResponse}
 */
proto.proto.GetPollLoadAnswersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetPollLoadAnswersResponse;
  return proto.proto.GetPollLoadAnswersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetPollLoadAnswersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetPollLoadAnswersResponse}
 */
proto.proto.GetPollLoadAnswersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollQuestion;
      reader.readMessage(value,proto.proto.PollQuestion.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetPollLoadAnswersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetPollLoadAnswersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetPollLoadAnswersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetPollLoadAnswersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.PollQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PollQuestion items = 1;
 * @return {!Array<!proto.proto.PollQuestion>}
 */
proto.proto.GetPollLoadAnswersResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.PollQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.PollQuestion, 1));
};


/**
 * @param {!Array<!proto.proto.PollQuestion>} value
 * @return {!proto.proto.GetPollLoadAnswersResponse} returns this
*/
proto.proto.GetPollLoadAnswersResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.PollQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.PollQuestion}
 */
proto.proto.GetPollLoadAnswersResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.PollQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.GetPollLoadAnswersResponse} returns this
 */
proto.proto.GetPollLoadAnswersResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadAnswerAccountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadAnswerAccountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAnswerAccountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && proto.proto.PollLoadPagination.toObject(includeInstance, f),
    answerId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadAnswerAccountsRequest}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadAnswerAccountsRequest;
  return proto.proto.ListPollLoadAnswerAccountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadAnswerAccountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadAnswerAccountsRequest}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollLoadPagination;
      reader.readMessage(value,proto.proto.PollLoadPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadAnswerAccountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadAnswerAccountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAnswerAccountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.PollLoadPagination.serializeBinaryToWriter
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional PollLoadPagination pagination = 1;
 * @return {?proto.proto.PollLoadPagination}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.PollLoadPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadPagination, 1));
};


/**
 * @param {?proto.proto.PollLoadPagination|undefined} value
 * @return {!proto.proto.ListPollLoadAnswerAccountsRequest} returns this
*/
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAnswerAccountsRequest} returns this
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 answer_id = 2;
 * @return {number}
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListPollLoadAnswerAccountsRequest} returns this
 */
proto.proto.ListPollLoadAnswerAccountsRequest.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListPollLoadAnswerAccountsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListPollLoadAnswerAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListPollLoadAnswerAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAnswerAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && proto.proto.PollLoadPagination.toObject(includeInstance, f),
    ssoIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListPollLoadAnswerAccountsResponse;
  return proto.proto.ListPollLoadAnswerAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListPollLoadAnswerAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.PollLoadPagination;
      reader.readMessage(value,proto.proto.PollLoadPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSsoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListPollLoadAnswerAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListPollLoadAnswerAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListPollLoadAnswerAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.PollLoadPagination.serializeBinaryToWriter
    );
  }
  f = message.getSsoIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional PollLoadPagination pagination = 1;
 * @return {?proto.proto.PollLoadPagination}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.getPagination = function() {
  return /** @type{?proto.proto.PollLoadPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.PollLoadPagination, 1));
};


/**
 * @param {?proto.proto.PollLoadPagination|undefined} value
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse} returns this
*/
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse} returns this
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string sso_id = 2;
 * @return {!Array<string>}
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.getSsoIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse} returns this
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.setSsoIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse} returns this
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.addSsoId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListPollLoadAnswerAccountsResponse} returns this
 */
proto.proto.ListPollLoadAnswerAccountsResponse.prototype.clearSsoIdList = function() {
  return this.setSsoIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListPollLoadAnswerAccountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListPollLoadAnswerAccountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListPollLoadAnswerAccountsResponse}
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListPollLoadAnswerAccountsResponse;
  return proto.proto.PersonalListPollLoadAnswerAccountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListPollLoadAnswerAccountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListPollLoadAnswerAccountsResponse}
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListPollLoadAnswerAccountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListPollLoadAnswerAccountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListPollLoadAnswerAccountsResponse} returns this
 */
proto.proto.PersonalListPollLoadAnswerAccountsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
