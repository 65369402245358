// source: project_intersection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.AccountDashboardProjectIntersection', null, global);
goog.exportSymbol('proto.proto.AccountListDashboardProjectIntersectionActivityRequest', null, global);
goog.exportSymbol('proto.proto.AccountListDashboardProjectIntersectionActivityResponse', null, global);
goog.exportSymbol('proto.proto.AccountListDashboardProjectIntersectionRequest', null, global);
goog.exportSymbol('proto.proto.AccountListDashboardProjectIntersectionResponse', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersection', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionFilter', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionFilterItem', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionPagination', null, global);
goog.exportSymbol('proto.proto.DashboardProjectIntersectionSort', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionActivityRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionActivityResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionRegistrationRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionRegistrationResponse', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectIntersectionResponse', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListDashboardProjectIntersectionRequest', null, global);
goog.exportSymbol('proto.proto.PersonalAccountListDashboardProjectIntersectionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersection.displayName = 'proto.proto.DashboardProjectIntersection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountDashboardProjectIntersection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AccountDashboardProjectIntersection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountDashboardProjectIntersection.displayName = 'proto.proto.AccountDashboardProjectIntersection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionFilterItem.displayName = 'proto.proto.DashboardProjectIntersectionFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardProjectIntersectionFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionFilter.displayName = 'proto.proto.DashboardProjectIntersectionFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionSort.displayName = 'proto.proto.DashboardProjectIntersectionSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectIntersectionPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectIntersectionPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectIntersectionPagination.displayName = 'proto.proto.DashboardProjectIntersectionPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionRequest.displayName = 'proto.proto.ListDashboardProjectIntersectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionResponse.displayName = 'proto.proto.ListDashboardProjectIntersectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionActivityRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionActivityRequest.displayName = 'proto.proto.ListDashboardProjectIntersectionActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionActivityResponse.displayName = 'proto.proto.ListDashboardProjectIntersectionActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionRegistrationRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionRegistrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionRegistrationRequest.displayName = 'proto.proto.ListDashboardProjectIntersectionRegistrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectIntersectionRegistrationResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectIntersectionRegistrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectIntersectionRegistrationResponse.displayName = 'proto.proto.ListDashboardProjectIntersectionRegistrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListDashboardProjectIntersectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListDashboardProjectIntersectionRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListDashboardProjectIntersectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListDashboardProjectIntersectionRequest.displayName = 'proto.proto.AccountListDashboardProjectIntersectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListDashboardProjectIntersectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListDashboardProjectIntersectionResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListDashboardProjectIntersectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListDashboardProjectIntersectionResponse.displayName = 'proto.proto.AccountListDashboardProjectIntersectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListDashboardProjectIntersectionActivityRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListDashboardProjectIntersectionActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListDashboardProjectIntersectionActivityRequest.displayName = 'proto.proto.AccountListDashboardProjectIntersectionActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AccountListDashboardProjectIntersectionActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.AccountListDashboardProjectIntersectionActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AccountListDashboardProjectIntersectionActivityResponse.displayName = 'proto.proto.AccountListDashboardProjectIntersectionActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.PersonalAccountListDashboardProjectIntersectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.displayName = 'proto.proto.PersonalAccountListDashboardProjectIntersectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalAccountListDashboardProjectIntersectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.displayName = 'proto.proto.PersonalAccountListDashboardProjectIntersectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.displayName = 'proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.displayName = 'proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersection.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersection.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectYes: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.DashboardProjectIntersection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersection;
  return proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectYes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getProjectYes();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 account_count = 1;
 * @return {number}
 */
proto.proto.DashboardProjectIntersection.prototype.getAccountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersection} returns this
 */
proto.proto.DashboardProjectIntersection.prototype.setAccountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string project_yes = 2;
 * @return {string}
 */
proto.proto.DashboardProjectIntersection.prototype.getProjectYes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersection} returns this
 */
proto.proto.DashboardProjectIntersection.prototype.setProjectYes = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountDashboardProjectIntersection.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountDashboardProjectIntersection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountDashboardProjectIntersection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountDashboardProjectIntersection.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountGuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountDashboardProjectIntersection}
 */
proto.proto.AccountDashboardProjectIntersection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountDashboardProjectIntersection;
  return proto.proto.AccountDashboardProjectIntersection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountDashboardProjectIntersection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountDashboardProjectIntersection}
 */
proto.proto.AccountDashboardProjectIntersection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountDashboardProjectIntersection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountDashboardProjectIntersection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountDashboardProjectIntersection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountDashboardProjectIntersection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_guid = 1;
 * @return {string}
 */
proto.proto.AccountDashboardProjectIntersection.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountDashboardProjectIntersection} returns this
 */
proto.proto.AccountDashboardProjectIntersection.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sso_id = 2;
 * @return {string}
 */
proto.proto.AccountDashboardProjectIntersection.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountDashboardProjectIntersection} returns this
 */
proto.proto.AccountDashboardProjectIntersection.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionFilterItem;
  return proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DashboardProjectIntersectionFilter or = 4;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 4));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
*/
proto.proto.DashboardProjectIntersectionFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem} returns this
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.DashboardProjectIntersectionFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardProjectIntersectionFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersectionFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionFilter;
  return proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilterItem;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersectionFilterItem items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersectionFilterItem>}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersectionFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersectionFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersectionFilterItem>} value
 * @return {!proto.proto.DashboardProjectIntersectionFilter} returns this
*/
proto.proto.DashboardProjectIntersectionFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersectionFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersectionFilterItem}
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersectionFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardProjectIntersectionFilter} returns this
 */
proto.proto.DashboardProjectIntersectionFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.DashboardProjectIntersectionSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionSort;
  return proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionSort} returns this
 */
proto.proto.DashboardProjectIntersectionSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardProjectIntersectionSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectIntersectionSort} returns this
 */
proto.proto.DashboardProjectIntersectionSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectIntersectionPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectIntersectionPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.DashboardProjectIntersectionPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectIntersectionPagination;
  return proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectIntersectionPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectIntersectionPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectIntersectionPagination} returns this
 */
proto.proto.DashboardProjectIntersectionPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardProjectIntersectionSort.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionRequest;
  return proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionSort;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionSort sort = 2;
 * @return {?proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionSort, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionSort|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectIntersectionRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse}
 */
proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionResponse;
  return proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse}
 */
proto.proto.ListDashboardProjectIntersectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersection;
      reader.readMessage(value,proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectIntersectionRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectIntersectionRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectIntersectionRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersection>}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersection>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectIntersectionRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectIntersectionRequest}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectIntersectionRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectIntersectionRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectIntersectionRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardProjectIntersectionSort.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionActivityRequest;
  return proto.proto.ListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionSort;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionSort sort = 2;
 * @return {?proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionSort, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionSort|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional float value = 8;
 * @return {number}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string date_begin = 9;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_end = 10;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectIntersectionActivityRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionActivityResponse;
  return proto.proto.ListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersection;
      reader.readMessage(value,proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectIntersectionActivityRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersection>}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersection>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectIntersectionActivityRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectIntersectionActivityRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectIntersectionActivityRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectIntersectionActivityRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionActivityResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionActivityResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionRegistrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.DashboardProjectIntersectionSort.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionRegistrationRequest;
  return proto.proto.ListDashboardProjectIntersectionRegistrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionSort;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionRegistrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionSort.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionSort sort = 2;
 * @return {?proto.proto.DashboardProjectIntersectionSort}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionSort, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionSort|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
*/
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectIntersectionRegistrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectIntersectionRegistrationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectIntersectionRegistrationResponse;
  return proto.proto.ListDashboardProjectIntersectionRegistrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersection;
      reader.readMessage(value,proto.proto.DashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectIntersectionRegistrationRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectIntersectionRegistrationRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectIntersectionRegistrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectIntersectionRegistrationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.DashboardProjectIntersection>}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectIntersection>} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectIntersection}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectIntersectionRegistrationRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectIntersectionRegistrationRequest}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectIntersectionRegistrationRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectIntersectionRegistrationRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectIntersectionRegistrationRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} returns this
*/
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectIntersectionRegistrationResponse} returns this
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectIntersectionRegistrationResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.repeatedFields_ = [3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListDashboardProjectIntersectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListDashboardProjectIntersectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardProjectIntersectionPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectYesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListDashboardProjectIntersectionRequest;
  return proto.proto.AccountListDashboardProjectIntersectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionPagination;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectYes(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListDashboardProjectIntersectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectYesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionPagination pagination = 2;
 * @return {?proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionPagination, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionPagination|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_yes = 7;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getProjectYesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setProjectYesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.addProjectYes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.clearProjectYesList = function() {
  return this.setProjectYesList([]);
};


/**
 * optional string date_begin = 8;
 * @return {string}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_end = 9;
 * @return {string}
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListDashboardProjectIntersectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListDashboardProjectIntersectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountDashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.AccountListDashboardProjectIntersectionRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListDashboardProjectIntersectionResponse;
  return proto.proto.AccountListDashboardProjectIntersectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountDashboardProjectIntersection;
      reader.readMessage(value,proto.proto.AccountDashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.AccountListDashboardProjectIntersectionRequest;
      reader.readMessage(value,proto.proto.AccountListDashboardProjectIntersectionRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListDashboardProjectIntersectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountDashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AccountListDashboardProjectIntersectionRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountDashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.AccountDashboardProjectIntersection>}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountDashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountDashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.AccountDashboardProjectIntersection>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountDashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountDashboardProjectIntersection}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountDashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional AccountListDashboardProjectIntersectionRequest params = 2;
 * @return {?proto.proto.AccountListDashboardProjectIntersectionRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.AccountListDashboardProjectIntersectionRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountListDashboardProjectIntersectionRequest, 2));
};


/**
 * @param {?proto.proto.AccountListDashboardProjectIntersectionRequest|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.repeatedFields_ = [3,4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListDashboardProjectIntersectionActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardProjectIntersectionPagination.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    projectYesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListDashboardProjectIntersectionActivityRequest;
  return proto.proto.AccountListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectIntersectionPagination;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectYes(values[i]);
      }
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectIntersectionPagination.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getProjectYesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      8,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectIntersectionPagination pagination = 2;
 * @return {?proto.proto.DashboardProjectIntersectionPagination}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionPagination, 2));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionPagination|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 activity_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated uint64 project_yes = 8;
 * @return {!Array<number>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getProjectYesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setProjectYesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.addProjectYes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.clearProjectYesList = function() {
  return this.setProjectYesList([]);
};


/**
 * optional float value = 9;
 * @return {number}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string date_begin = 10;
 * @return {string}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_end = 11;
 * @return {string}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AccountListDashboardProjectIntersectionActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AccountDashboardProjectIntersection.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.AccountListDashboardProjectIntersectionActivityRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AccountListDashboardProjectIntersectionActivityResponse;
  return proto.proto.AccountListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AccountDashboardProjectIntersection;
      reader.readMessage(value,proto.proto.AccountDashboardProjectIntersection.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.AccountListDashboardProjectIntersectionActivityRequest;
      reader.readMessage(value,proto.proto.AccountListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AccountListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AccountDashboardProjectIntersection.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AccountListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountDashboardProjectIntersection items = 1;
 * @return {!Array<!proto.proto.AccountDashboardProjectIntersection>}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AccountDashboardProjectIntersection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AccountDashboardProjectIntersection, 1));
};


/**
 * @param {!Array<!proto.proto.AccountDashboardProjectIntersection>} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AccountDashboardProjectIntersection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AccountDashboardProjectIntersection}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AccountDashboardProjectIntersection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional AccountListDashboardProjectIntersectionActivityRequest params = 2;
 * @return {?proto.proto.AccountListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.AccountListDashboardProjectIntersectionActivityRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.AccountListDashboardProjectIntersectionActivityRequest, 2));
};


/**
 * @param {?proto.proto.AccountListDashboardProjectIntersectionActivityRequest|undefined} value
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} returns this
*/
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AccountListDashboardProjectIntersectionActivityResponse} returns this
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AccountListDashboardProjectIntersectionActivityResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.repeatedFields_ = [2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    projectYesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    dateBegin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListDashboardProjectIntersectionRequest;
  return proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectYes(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getProjectYesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
*/
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 activity_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_yes = 6;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getProjectYesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setProjectYesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.addProjectYes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.clearProjectYesList = function() {
  return this.setProjectYesList([]);
};


/**
 * optional string date_begin = 7;
 * @return {string}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string date_end = 8;
 * @return {string}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListDashboardProjectIntersectionResponse;
  return proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionResponse} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.repeatedFields_ = [2,3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.DashboardProjectIntersectionFilter.toObject(includeInstance, f),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    projectYesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest;
  return proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectIntersectionFilter;
      reader.readMessage(value,proto.proto.DashboardProjectIntersectionFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectYes(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectIntersectionFilter.serializeBinaryToWriter
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      6,
      f
    );
  }
  f = message.getProjectYesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional DashboardProjectIntersectionFilter filter = 1;
 * @return {?proto.proto.DashboardProjectIntersectionFilter}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.DashboardProjectIntersectionFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectIntersectionFilter, 1));
};


/**
 * @param {?proto.proto.DashboardProjectIntersectionFilter|undefined} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
*/
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated uint64 activity_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 4;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 5;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};


/**
 * repeated uint64 project_ids = 6;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated uint64 project_yes = 7;
 * @return {!Array<number>}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getProjectYesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setProjectYesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.addProjectYes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.clearProjectYesList = function() {
  return this.setProjectYesList([]);
};


/**
 * optional float value = 8;
 * @return {number}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string date_begin = 9;
 * @return {string}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_end = 10;
 * @return {string}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse;
  return proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse} returns this
 */
proto.proto.PersonalAccountListDashboardProjectIntersectionActivityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
