import UserProjects from "../views/UserProjects.vue";
import UserLoyalty from "../views/UserLoyalty.vue";
const routes = [
  {
    path: "/user-profile",
    name: "UserProjects",
    component: UserProjects,
    meta: {
      breadCrumb: [
        { text: "Главная", to: { name: "HomePage" }, icon: "home" },
        { text: "Цифровой профиль пользователя" },
      ],
    },
  },
  {
    path: "/user-profile/:id",
    name: "UserProfile",
    component: () => import("../views/UserProfile.vue"),
    meta: {
      breadCrumb: [
        {
          text: "Цифровой профиль пользователя",
          to: { name: "UserProjects" },
        },
        { text: "Пользователь" },
      ],
    },
  },
  {
    path: "/user-loyalty",
    name: "UserLoyalty",
    component: UserLoyalty,
    meta: {
      breadCrumb: [
        {
          text: "Главная",
          to: { name: "HomePage" },
          icon: "home",
        },
        { text: "Сегментация аудитории" },
      ],
    },
  },
];

export default {
  routes,
};
