/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: activity_count.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as activity_count_pb from './activity_count_pb';


export class DashboardActivityCountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetDashboardActivityCount = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityCountService/GetDashboardActivityCount',
    grpcWeb.MethodType.UNARY,
    activity_count_pb.GetDashboardActivityCountRequest,
    activity_count_pb.GetDashboardActivityCountResponse,
    (request: activity_count_pb.GetDashboardActivityCountRequest) => {
      return request.serializeBinary();
    },
    activity_count_pb.GetDashboardActivityCountResponse.deserializeBinary
  );

  getDashboardActivityCount(
    request: activity_count_pb.GetDashboardActivityCountRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_count_pb.GetDashboardActivityCountResponse>;

  getDashboardActivityCount(
    request: activity_count_pb.GetDashboardActivityCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_count_pb.GetDashboardActivityCountResponse) => void): grpcWeb.ClientReadableStream<activity_count_pb.GetDashboardActivityCountResponse>;

  getDashboardActivityCount(
    request: activity_count_pb.GetDashboardActivityCountRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_count_pb.GetDashboardActivityCountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityCountService/GetDashboardActivityCount',
        request,
        metadata || {},
        this.methodDescriptorGetDashboardActivityCount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityCountService/GetDashboardActivityCount',
    request,
    metadata || {},
    this.methodDescriptorGetDashboardActivityCount);
  }

  methodDescriptorListDashboardActivityCountDetails = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityCountService/ListDashboardActivityCountDetails',
    grpcWeb.MethodType.UNARY,
    activity_count_pb.ListDashboardActivityCountDetailsRequest,
    activity_count_pb.ListDashboardActivityCountDetailsResponse,
    (request: activity_count_pb.ListDashboardActivityCountDetailsRequest) => {
      return request.serializeBinary();
    },
    activity_count_pb.ListDashboardActivityCountDetailsResponse.deserializeBinary
  );

  listDashboardActivityCountDetails(
    request: activity_count_pb.ListDashboardActivityCountDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_count_pb.ListDashboardActivityCountDetailsResponse>;

  listDashboardActivityCountDetails(
    request: activity_count_pb.ListDashboardActivityCountDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_count_pb.ListDashboardActivityCountDetailsResponse) => void): grpcWeb.ClientReadableStream<activity_count_pb.ListDashboardActivityCountDetailsResponse>;

  listDashboardActivityCountDetails(
    request: activity_count_pb.ListDashboardActivityCountDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_count_pb.ListDashboardActivityCountDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityCountService/ListDashboardActivityCountDetails',
        request,
        metadata || {},
        this.methodDescriptorListDashboardActivityCountDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityCountService/ListDashboardActivityCountDetails',
    request,
    metadata || {},
    this.methodDescriptorListDashboardActivityCountDetails);
  }

  methodDescriptorListDashboardActivityCountDetailsInterval = new grpcWeb.MethodDescriptor(
    '/proto.DashboardActivityCountService/ListDashboardActivityCountDetailsInterval',
    grpcWeb.MethodType.UNARY,
    activity_count_pb.ListDashboardActivityCountDetailsIntervalRequest,
    activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse,
    (request: activity_count_pb.ListDashboardActivityCountDetailsIntervalRequest) => {
      return request.serializeBinary();
    },
    activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse.deserializeBinary
  );

  listDashboardActivityCountDetailsInterval(
    request: activity_count_pb.ListDashboardActivityCountDetailsIntervalRequest,
    metadata: grpcWeb.Metadata | null): Promise<activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse>;

  listDashboardActivityCountDetailsInterval(
    request: activity_count_pb.ListDashboardActivityCountDetailsIntervalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse) => void): grpcWeb.ClientReadableStream<activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse>;

  listDashboardActivityCountDetailsInterval(
    request: activity_count_pb.ListDashboardActivityCountDetailsIntervalRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activity_count_pb.ListDashboardActivityCountDetailsIntervalResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.DashboardActivityCountService/ListDashboardActivityCountDetailsInterval',
        request,
        metadata || {},
        this.methodDescriptorListDashboardActivityCountDetailsInterval,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.DashboardActivityCountService/ListDashboardActivityCountDetailsInterval',
    request,
    metadata || {},
    this.methodDescriptorListDashboardActivityCountDetailsInterval);
  }

}

