// source: project_registration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.proto.DashboardIntervalRegistration', null, global);
goog.exportSymbol('proto.proto.DashboardProjectRegistration', null, global);
goog.exportSymbol('proto.proto.DashboardProjectRegistrationPagination', null, global);
goog.exportSymbol('proto.proto.DashboardProjectRegistrationSort', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectRegistrationRequest', null, global);
goog.exportSymbol('proto.proto.ListDashboardProjectRegistrationResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardIntervalRegistration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardIntervalRegistration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardIntervalRegistration.displayName = 'proto.proto.DashboardIntervalRegistration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectRegistration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DashboardProjectRegistration.repeatedFields_, null);
};
goog.inherits(proto.proto.DashboardProjectRegistration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectRegistration.displayName = 'proto.proto.DashboardProjectRegistration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectRegistrationSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectRegistrationSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectRegistrationSort.displayName = 'proto.proto.DashboardProjectRegistrationSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DashboardProjectRegistrationPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DashboardProjectRegistrationPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DashboardProjectRegistrationPagination.displayName = 'proto.proto.DashboardProjectRegistrationPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectRegistrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectRegistrationRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectRegistrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectRegistrationRequest.displayName = 'proto.proto.ListDashboardProjectRegistrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListDashboardProjectRegistrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListDashboardProjectRegistrationResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListDashboardProjectRegistrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListDashboardProjectRegistrationResponse.displayName = 'proto.proto.ListDashboardProjectRegistrationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardIntervalRegistration.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardIntervalRegistration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardIntervalRegistration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardIntervalRegistration.toObject = function(includeInstance, msg) {
  var f, obj = {
    regCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    regInterval: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardIntervalRegistration}
 */
proto.proto.DashboardIntervalRegistration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardIntervalRegistration;
  return proto.proto.DashboardIntervalRegistration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardIntervalRegistration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardIntervalRegistration}
 */
proto.proto.DashboardIntervalRegistration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRegCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardIntervalRegistration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardIntervalRegistration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardIntervalRegistration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardIntervalRegistration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getRegInterval();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 reg_count = 1;
 * @return {number}
 */
proto.proto.DashboardIntervalRegistration.prototype.getRegCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardIntervalRegistration} returns this
 */
proto.proto.DashboardIntervalRegistration.prototype.setRegCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string reg_interval = 2;
 * @return {string}
 */
proto.proto.DashboardIntervalRegistration.prototype.getRegInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardIntervalRegistration} returns this
 */
proto.proto.DashboardIntervalRegistration.prototype.setRegInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DashboardProjectRegistration.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectRegistration.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectRegistration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectRegistration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistration.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectShortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectIdx: jspb.Message.getFieldWithDefault(msg, 4, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardIntervalRegistration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectRegistration}
 */
proto.proto.DashboardProjectRegistration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectRegistration;
  return proto.proto.DashboardProjectRegistration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectRegistration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectRegistration}
 */
proto.proto.DashboardProjectRegistration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectShortName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProjectIdx(value);
      break;
    case 5:
      var value = new proto.proto.DashboardIntervalRegistration;
      reader.readMessage(value,proto.proto.DashboardIntervalRegistration.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectRegistration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectRegistration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectRegistration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectIdx();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.proto.DashboardIntervalRegistration.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 project_id = 1;
 * @return {number}
 */
proto.proto.DashboardProjectRegistration.prototype.getProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistration} returns this
 */
proto.proto.DashboardProjectRegistration.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.proto.DashboardProjectRegistration.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectRegistration} returns this
 */
proto.proto.DashboardProjectRegistration.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_short_name = 3;
 * @return {string}
 */
proto.proto.DashboardProjectRegistration.prototype.getProjectShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectRegistration} returns this
 */
proto.proto.DashboardProjectRegistration.prototype.setProjectShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 project_idx = 4;
 * @return {number}
 */
proto.proto.DashboardProjectRegistration.prototype.getProjectIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistration} returns this
 */
proto.proto.DashboardProjectRegistration.prototype.setProjectIdx = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DashboardIntervalRegistration items = 5;
 * @return {!Array<!proto.proto.DashboardIntervalRegistration>}
 */
proto.proto.DashboardProjectRegistration.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardIntervalRegistration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardIntervalRegistration, 5));
};


/**
 * @param {!Array<!proto.proto.DashboardIntervalRegistration>} value
 * @return {!proto.proto.DashboardProjectRegistration} returns this
*/
proto.proto.DashboardProjectRegistration.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.proto.DashboardIntervalRegistration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardIntervalRegistration}
 */
proto.proto.DashboardProjectRegistration.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.proto.DashboardIntervalRegistration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DashboardProjectRegistration} returns this
 */
proto.proto.DashboardProjectRegistration.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectRegistrationSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectRegistrationSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectRegistrationSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistrationSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectRegistrationSort}
 */
proto.proto.DashboardProjectRegistrationSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectRegistrationSort;
  return proto.proto.DashboardProjectRegistrationSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectRegistrationSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectRegistrationSort}
 */
proto.proto.DashboardProjectRegistrationSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectRegistrationSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectRegistrationSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectRegistrationSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistrationSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.DashboardProjectRegistrationSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectRegistrationSort} returns this
 */
proto.proto.DashboardProjectRegistrationSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.DashboardProjectRegistrationSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DashboardProjectRegistrationSort} returns this
 */
proto.proto.DashboardProjectRegistrationSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DashboardProjectRegistrationPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DashboardProjectRegistrationPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistrationPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DashboardProjectRegistrationPagination}
 */
proto.proto.DashboardProjectRegistrationPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DashboardProjectRegistrationPagination;
  return proto.proto.DashboardProjectRegistrationPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DashboardProjectRegistrationPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DashboardProjectRegistrationPagination}
 */
proto.proto.DashboardProjectRegistrationPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DashboardProjectRegistrationPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DashboardProjectRegistrationPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DashboardProjectRegistrationPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistrationPagination} returns this
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistrationPagination} returns this
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 pages = 3;
 * @return {number}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistrationPagination} returns this
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 cnt = 4;
 * @return {number}
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DashboardProjectRegistrationPagination} returns this
 */
proto.proto.DashboardProjectRegistrationPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectRegistrationRequest.repeatedFields_ = [3,7,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectRegistrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectRegistrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectRegistrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sort: (f = msg.getSort()) && proto.proto.DashboardProjectRegistrationSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.DashboardProjectRegistrationPagination.toObject(includeInstance, f),
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    typeInterval: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateBegin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dateEnd: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activityIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    loyaltyIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    usefulnessIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    interestIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest}
 */
proto.proto.ListDashboardProjectRegistrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectRegistrationRequest;
  return proto.proto.ListDashboardProjectRegistrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectRegistrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest}
 */
proto.proto.ListDashboardProjectRegistrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectRegistrationSort;
      reader.readMessage(value,proto.proto.DashboardProjectRegistrationSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 2:
      var value = new proto.proto.DashboardProjectRegistrationPagination;
      reader.readMessage(value,proto.proto.DashboardProjectRegistrationPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProjectIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeInterval(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateBegin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateEnd(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActivityIds(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoyaltyIds(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUsefulnessIds(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInterestIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectRegistrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectRegistrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectRegistrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.DashboardProjectRegistrationSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.DashboardProjectRegistrationPagination.serializeBinaryToWriter
    );
  }
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      3,
      f
    );
  }
  f = message.getTypeInterval();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateBegin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDateEnd();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActivityIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      7,
      f
    );
  }
  f = message.getLoyaltyIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      8,
      f
    );
  }
  f = message.getUsefulnessIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      9,
      f
    );
  }
  f = message.getInterestIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      10,
      f
    );
  }
};


/**
 * optional DashboardProjectRegistrationSort sort = 1;
 * @return {?proto.proto.DashboardProjectRegistrationSort}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.DashboardProjectRegistrationSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectRegistrationSort, 1));
};


/**
 * @param {?proto.proto.DashboardProjectRegistrationSort|undefined} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
*/
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DashboardProjectRegistrationPagination pagination = 2;
 * @return {?proto.proto.DashboardProjectRegistrationPagination}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.DashboardProjectRegistrationPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.DashboardProjectRegistrationPagination, 2));
};


/**
 * @param {?proto.proto.DashboardProjectRegistrationPagination|undefined} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
*/
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated uint64 project_ids = 3;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getProjectIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * optional string type_interval = 4;
 * @return {string}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getTypeInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setTypeInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_begin = 5;
 * @return {string}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getDateBegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setDateBegin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date_end = 6;
 * @return {string}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getDateEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setDateEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated uint64 activity_ids = 7;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getActivityIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setActivityIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.addActivityIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearActivityIdsList = function() {
  return this.setActivityIdsList([]);
};


/**
 * repeated uint64 loyalty_ids = 8;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getLoyaltyIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setLoyaltyIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.addLoyaltyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearLoyaltyIdsList = function() {
  return this.setLoyaltyIdsList([]);
};


/**
 * repeated uint64 usefulness_ids = 9;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getUsefulnessIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setUsefulnessIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.addUsefulnessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearUsefulnessIdsList = function() {
  return this.setUsefulnessIdsList([]);
};


/**
 * repeated uint64 interest_ids = 10;
 * @return {!Array<number>}
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.getInterestIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.setInterestIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.addInterestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationRequest} returns this
 */
proto.proto.ListDashboardProjectRegistrationRequest.prototype.clearInterestIdsList = function() {
  return this.setInterestIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListDashboardProjectRegistrationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListDashboardProjectRegistrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListDashboardProjectRegistrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectRegistrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.DashboardProjectRegistration.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListDashboardProjectRegistrationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse}
 */
proto.proto.ListDashboardProjectRegistrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListDashboardProjectRegistrationResponse;
  return proto.proto.ListDashboardProjectRegistrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListDashboardProjectRegistrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse}
 */
proto.proto.ListDashboardProjectRegistrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DashboardProjectRegistration;
      reader.readMessage(value,proto.proto.DashboardProjectRegistration.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListDashboardProjectRegistrationRequest;
      reader.readMessage(value,proto.proto.ListDashboardProjectRegistrationRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListDashboardProjectRegistrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListDashboardProjectRegistrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListDashboardProjectRegistrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DashboardProjectRegistration.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListDashboardProjectRegistrationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DashboardProjectRegistration items = 1;
 * @return {!Array<!proto.proto.DashboardProjectRegistration>}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.DashboardProjectRegistration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DashboardProjectRegistration, 1));
};


/**
 * @param {!Array<!proto.proto.DashboardProjectRegistration>} value
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse} returns this
*/
proto.proto.ListDashboardProjectRegistrationResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DashboardProjectRegistration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DashboardProjectRegistration}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DashboardProjectRegistration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse} returns this
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListDashboardProjectRegistrationRequest params = 2;
 * @return {?proto.proto.ListDashboardProjectRegistrationRequest}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListDashboardProjectRegistrationRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListDashboardProjectRegistrationRequest, 2));
};


/**
 * @param {?proto.proto.ListDashboardProjectRegistrationRequest|undefined} value
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse} returns this
*/
proto.proto.ListDashboardProjectRegistrationResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListDashboardProjectRegistrationResponse} returns this
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListDashboardProjectRegistrationResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
